const translation = {
  general: {
    minute_one: '{{ count }} minute',
    minute_other: '{{ count }} minutes',
    hour_one: '{{ count }} hour',
    hour_other: '{{ count }} hours',
  },
  app: {
    exit: 'Exit',
    close: 'Close',
    footer: 'Powered by One Click Go Live Limited',
    'login-btn': 'Log In',
    'logout-btn': 'Log Out',
    confirm: 'Confirm',
  },
  login: {
    title: 'User Login',
    'email-label': 'Enter Email',
    'not-registered-remark':
      "If not registered yet, please enter your email address and click the 'Confirm' button below",
    'otp-guide-title': 'OTP Sent',
    'otp-label': 'Enter Your OTP',
    'otp-remark-1': 'We have sent your OTP to the following email address',
    'otp-remark-2': 'Please complete the login process within 15 minutes',
    'next-step': 'Next Step',
    'email-error': 'Incorrect email, please login again',
    'unknown-error': 'Error, please login again',
    'network-error':
      'Network connectivity issue detected. Please try a different network if the problem persists.',
    'otp-error': 'Incorrect OTP, please login again',
    'otp-fatal-error': 'OTP timeout or OTP was incorrect for more than 3 times, please login again',
  },
  home: {
    title: 'home',
  },
  event: {
    info: 'Info',
    'q-and-a': 'Q&A',
    question: {
      placeholder: 'There will be a Q&A session. Please submit your question here.',
      submit: 'Submit',
      'succeed-message': 'Thank you for submitting your question',
      'failed-message': 'Submission failed, please try again later',
      'exceeds-char-limit-message': 'You can enter up to 200 characters',
    },
    chatroom: 'Chatroom',
    polling: 'Polling',
    schedule: 'Schedule',
    si: {
      english: 'eng',
      cantonese: 'can',
      putonghua: 'pth',
    },
    playback: {
      'error-title': 'Video playback failure.',
      'error-message':
        'Either your network environment is unstable or you are not authorized to access this video.',
    },
    'just-started': 'Just started',
    'started-ago': 'Started {{age}} ago',
    'concurrent-error-message': 'Your account is logged in on another device',
    'connection-error-message': 'Network not stable',
    'require-login-message': "Log in if you've been granted access to this event",
    'session-kicked-error-message':
      'Your account is logged in on another device, click below button to view on this device',
    'view-here': 'View Here',
    unauthorized: 'Unauthorized to view',
  },
  passcode: {
    label: 'Please input your passcode:',
    enter: 'Enter',
    remark: 'Please contact event organiser if you have not received your passcode yet',
    error: 'Incorrect Passcode',
    'select-placeholder': 'Please Select',
  },
  register: {
    'step-1-label': 'Event Information',
    'step-2-label': 'Fill RSVP Form',
    'step-3-label': 'Confirm',
    'step-4-label': 'Finish',
    'register-finish-title': 'Finished! Your registration was successful.',
    'register-finish-desc-1': 'Please show the below QR code during check-in',
    'register-finish-desc-2': 'You may take screenshot or download the image',
    'confirm-your-registration': 'Confirm Your Registration',
    'label-download-image': 'Download Image',
    'label-join-now': 'Register Now',
    'label-back': 'Back',
    'label-submit': 'Submit',
    'label-confirm': 'Confirm',
    'label-region': 'Region',
    'label-required-field': '* Indicates required field',
    'label-register-form': 'Register Form',
    'error-fail-download-image': 'Fail to download image.',
    'error-email-registered': 'This email address is already registered.',
    'error-fail-register': 'Fail to sumbit form. Please try again later.',
    'error-fail-email-not-found': 'Fail to sumbit form. Email field not found.',
  },
};

export default translation;
