import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FormLocaleSwitcher = (props) => {
  const { supportedLocales, ...rest } = props;
  const { i18n } = useTranslation();

  const getDisplayLabel = (locale) => {
    switch (locale) {
      case 'zh-Hans':
        return '简';
      case 'zh-Hant':
        return '繁';
      default:
        return 'Eng';
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }} {...rest}>
      {supportedLocales.map((it) => {
        return (
          <Button key={it} disabled={i18n.language === it} onClick={() => i18n.changeLanguage(it)}>
            {getDisplayLabel(it)}
          </Button>
        );
      })}
    </Box>
  );
};

export default FormLocaleSwitcher;
