import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as API from 'api/baseAPI';
import logo from 'images/logo.png';
import logoEn from 'images/header_logo_en.png';
import logoEn2x from 'images/header_logo_en@2x.png';
import beianLogo from 'images/beian.png';
import { getMultiLocaleContent } from 'utils/helper';
import { setGlobalLoading } from 'redux/appSlice';
import CircularProgress from '@mui/material/CircularProgress';
import LocaleSelector from 'components/localeSelector';
import { DialogContext } from 'components/dialogContext';
import 'styles/scss/components/passcodePage.scss';

const defaultCustomConfig = {
  enableLocale: false,
  availableLocales: ['en', 'zh-Hans', 'zh-Hant'],
  metadata: [],
  logoImageUrl: null,
  logoImageUrlMobile: null,
  logoInHeader: false,
  footerImageUrl: null,
  footerText: null,
  footerLink: null,
  hideFooter: false,
  hideFooterLink: false,
  mobileBanner: null,
  mobileBottomBanner: null,
  label: null,
  title: null,
  submitButtonText: null,
  remarks: [],
  ignoreCase: false,
  autoCapitalize: false,
  errorMessage: null,
  hideInputForm: false,
  tabletBreakpoint: 768,
  desktopBreakpoint: 992,
};

export default function PasscodePage(props) {
  const { eventId, title, passcodeValidating, validatePasscodeSuccessHandler } = props;
  const customization = useSelector((state) => state.event.customization);
  const [passcode, setPasscode] = useState('');
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [customStyle, setCustomStyle] = useState({});
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [customConfig, setCustomConfig] = useState(defaultCustomConfig);
  const metadataRefs = useRef([]);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const passcodeChangeHandler = (e) => {
    setPasscode(e.target.value);
    validate(e.target.value);
  };

  const metadataChangeHandler = () => {
    validate(passcode);
  };

  const validate = (_passcode) => {
    let valid = _passcode !== '';
    if (valid) {
      if (customConfig.metadata && customConfig.metadata.length > 0) {
        metadataRefs.current.forEach((el, index) => {
          if (el.type === 'checkbox') {
            let value = el.checked;
            if (customConfig.metadata[index].required) {
              if (value === false) {
                valid = false;
              }
            }
          } else {
            let value = el.value;
            if (customConfig.metadata[index].required) {
              if (value === '') {
                valid = false;
              }
            }

            if (customConfig.metadata[index].validation) {
              const regex = new RegExp(customConfig.metadata[index].validation);
              if (!regex.test(value)) {
                valid = false;
              }
            }
          }
        });
      }
    }

    setSubmittable(valid);
  };

  const passcodeSubmitHandler = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (passcode === '') {
      return;
    }
    let _passcode = passcode;
    if (loading || passcodeValidating) {
      return;
    }
    setLoading(true);
    if (customConfig.ignoreCase) {
      _passcode = _passcode.toLowerCase();
    } else if (customConfig.autoCapitalize) {
      _passcode = _passcode.toUpperCase();
    }
    const data = {
      passcode: _passcode.trim(),
      logAccess: true,
    };
    let metadataErrorMessage = '';
    if (customConfig.metadata && customConfig.metadata.length > 0) {
      const metadata = {};
      metadataRefs.current.forEach((el, index) => {
        let value = el.value;
        let requiredError = false;
        let validationError = false;
        if (el.type === 'checkbox') {
          value = el.checked;
          if (customConfig.metadata[index].required) {
            if (value === false && customConfig.metadata[index].requiredMessage) {
              requiredError = true;
            }
          }
        } else {
          if (customConfig.metadata[index].required) {
            if (value === '' && customConfig.metadata[index].requiredMessage) {
              requiredError = true;
            }
          }
          if (customConfig.metadata[index].validation) {
            const regex = new RegExp(customConfig.metadata[index].validation);
            if (!requiredError && !regex.test(value)) {
              validationError = true;
            }
          }
        }
        if (requiredError) {
          if (metadataErrorMessage === '') {
            metadataErrorMessage = getMultiLocaleContent(
              customConfig.metadata[index].requiredMessage,
              i18n.language
            );
          } else {
            metadataErrorMessage = `${metadataErrorMessage};\n${getMultiLocaleContent(
              customConfig.metadata[index].requiredMessage,
              i18n.language
            )}`;
          }
        }
        if (validationError) {
          if (metadataErrorMessage === '') {
            metadataErrorMessage = getMultiLocaleContent(
              customConfig.metadata[index].validationMessage,
              i18n.language
            );
          } else {
            metadataErrorMessage = `${metadataErrorMessage};\n${getMultiLocaleContent(
              customConfig.metadata[index].validationMessage,
              i18n.language
            )}`;
          }
        }

        metadata[customConfig.metadata[index]['key']] = value;
      });
      data['metadata'] = metadata;
    }
    if (metadataErrorMessage !== '') {
      setLoading(false);
      openDialog(metadataErrorMessage, closeDialog);
    } else {
      API.validatePasscode(eventId, data)
        .then((response) => {
          setLoading(false);
          validatePasscodeSuccessHandler(response.data, true);
        })
        .catch(() => {
          setLoading(false);
          openDialog(
            customConfig.errorMessage
              ? getMultiLocaleContent(customConfig.errorMessage, i18n.language)
              : t('passcode.error'),
            closeDialog
          );
        });
    }
  };

  const matchTabletSizeHandler = (e) => {
    setIsTablet(e.matches);
  };

  const matchDesktopSizeHandler = (e) => {
    setIsDesktop(e.matches);
  };

  useEffect(() => {
    const matchTabletQueryList = window.matchMedia(
      `(min-width: ${customConfig.tabletBreakpoint}px)`
    );
    const matchDesktopQueryList = window.matchMedia(
      `(min-width: ${customConfig.desktopBreakpoint}px)`
    );
    matchTabletSizeHandler(matchTabletQueryList);
    matchDesktopSizeHandler(matchDesktopQueryList);

    if (matchTabletQueryList.addEventListener) {
      matchTabletQueryList.addEventListener('change', matchTabletSizeHandler);
    } else {
      matchTabletQueryList.addListener(matchTabletSizeHandler);
    }
    if (matchDesktopQueryList.addEventListener) {
      matchDesktopQueryList.addEventListener('change', matchDesktopSizeHandler);
    } else {
      matchDesktopQueryList.addListener(matchDesktopSizeHandler);
    }

    return () => {
      if (matchTabletQueryList.removeEventListener) {
        matchTabletQueryList.removeEventListener('change', matchTabletSizeHandler);
      } else {
        matchTabletQueryList.removeListener(matchTabletSizeHandler);
      }
      if (matchDesktopQueryList.removeEventListener) {
        matchDesktopQueryList.removeEventListener('change', matchDesktopSizeHandler);
      } else {
        matchDesktopQueryList.removeListener(matchDesktopSizeHandler);
      }
    };
  }, [customConfig.desktopBreakpoint, customConfig.tabletBreakpoint]);

  useEffect(() => {
    setCustomStyle({});
    setCustomConfig(defaultCustomConfig);
    if (customization.passcode) {
      if (customization.passcode.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.passcode.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
      if (customization.passcode.config) {
        setCustomConfig({
          ...defaultCustomConfig,
          ...customization.passcode.config,
        });
      }
    }
    setInitialized(true);
  }, [customization]);

  useEffect(() => {
    if (searchParams.has('token') && initialized && !loading) {
      dispatch(setGlobalLoading(true));
      let _passcode = searchParams.get('token');
      setLoading(true);
      const data = {
        passcode: _passcode,
        logAccess: true,
      };
      API.validatePasscode(eventId, data)
        .then((response) => {
          searchParams.delete('token');
          setSearchParams(searchParams);
          setLoading(false);
          validatePasscodeSuccessHandler(response.data, true);
        })
        .catch(() => {
          searchParams.delete('token');
          setSearchParams(searchParams);
          setLoading(false);
          dispatch(setGlobalLoading(false));
          openDialog(
            customConfig.errorMessage
              ? getMultiLocaleContent(customConfig.errorMessage, i18n.language)
              : t('passcode.error'),
            closeDialog
          );
        });
    }
  }, [
    searchParams,
    setSearchParams,
    initialized,
    dispatch,
    closeDialog,
    customConfig.errorMessage,
    eventId,
    i18n.language,
    openDialog,
    t,
    validatePasscodeSuccessHandler,
    loading,
  ]);

  return (
    <div
      className={`passcode-page${isTablet ? ' tablet' : ''}${isDesktop ? ' desktop' : ''}`}
      style={customStyle}
    >
      <div className="header">
        <div className="logo-wrapper mobile">
          {customConfig.logoImageUrl ? (
            <React.Fragment>
              {customConfig.logoImageUrlMobile ? (
                <img src={customConfig.logoImageUrlMobile} className="logo" alt="logo" />
              ) : (
                <img src={customConfig.logoImageUrl} className="logo" alt="logo" />
              )}
            </React.Fragment>
          ) : (
            <img src={logoEn} className="logo" alt="logo" />
          )}
        </div>
        {customConfig.logoInHeader && (
          <div className="logo-wrapper">
            {customConfig.logoImageUrl ? (
              <img src={customConfig.logoImageUrl} className="logo" alt="logo" />
            ) : (
              <img src={logoEn2x} className="logo" alt="logo" />
            )}
          </div>
        )}
        {customConfig.enableLocale && (
          <LocaleSelector availableLocales={customConfig.availableLocales} />
        )}
      </div>
      <div className="content">
        {customConfig.mobileBanner && (
          <div className="mobile-banner">
            <img src={customConfig.mobileBanner} alt="banner"></img>
          </div>
        )}
        <div className="content-container">
          {!customConfig.logoInHeader && (
            <div className="logo-wrapper">
              {customConfig.logoImageUrl ? (
                <img src={customConfig.logoImageUrl} className="logo" alt="logo" />
              ) : (
                <img src={logoEn2x} className="logo" alt="logo" />
              )}
            </div>
          )}
          {customConfig.title !== null ? (
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: getMultiLocaleContent(customConfig.title, i18n.language),
              }}
            ></div>
          ) : (
            <div className="title">{title}</div>
          )}
          {!customConfig.hideInputForm && (
            <form onSubmit={passcodeSubmitHandler}>
              {customConfig.label ? (
                <label
                  className="input-label"
                  htmlFor={passcode}
                  dangerouslySetInnerHTML={{
                    __html: getMultiLocaleContent(customConfig.label, i18n.language),
                  }}
                ></label>
              ) : (
                <label className="input-label" htmlFor={passcode}>
                  {t('passcode.label')}
                </label>
              )}
              <div
                className={`input-container ${customConfig.metadata.length > 0 ? '' : 'inline'}`}
              >
                <div className="input-row top">
                  <input
                    className={`${customConfig.autoCapitalize ? 'capital' : ''}`}
                    type="text"
                    name="passcode"
                    value={passcode}
                    required={true}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck={false}
                    onChange={passcodeChangeHandler}
                  />
                </div>
                {customConfig.metadata.map((metadata, index) => {
                  return (
                    <div className="input-row" key={index}>
                      {metadata.type === 'textOnly' ? (
                        <label
                          className="input-label text-only"
                          dangerouslySetInnerHTML={{
                            __html: getMultiLocaleContent(metadata.label, i18n.language),
                          }}
                        ></label>
                      ) : metadata.type === 'checkbox' ? (
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            name={metadata.key}
                            id={metadata.key}
                            ref={(el) => (metadataRefs.current[index] = el)}
                            onChange={metadataChangeHandler}
                          />
                          <label className="input-label" htmlFor={metadata.key}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getMultiLocaleContent(metadata.label, i18n.language),
                              }}
                            ></span>
                          </label>
                        </div>
                      ) : (
                        <Fragment>
                          <label
                            className="input-label"
                            htmlFor={metadata.key}
                            dangerouslySetInnerHTML={{
                              __html: getMultiLocaleContent(metadata.label, i18n.language),
                            }}
                          ></label>
                          {metadata.type === 'select' ? (
                            <div className="select-wrapper">
                              <select
                                name={metadata.key}
                                id={metadata.key}
                                ref={(el) => (metadataRefs.current[index] = el)}
                                onChange={metadataChangeHandler}
                              >
                                <option value={''}>{t('passcode.select-placeholder')}</option>
                                {metadata.options &&
                                  metadata.options.map((option) => {
                                    return (
                                      <option value={option.value} key={option.value}>
                                        {getMultiLocaleContent(option.text, i18n.language)}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          ) : (
                            <input
                              type="text"
                              name={metadata.key}
                              id={metadata.key}
                              ref={(el) => (metadataRefs.current[index] = el)}
                              onChange={metadataChangeHandler}
                            />
                          )}
                        </Fragment>
                      )}
                    </div>
                  );
                })}
                <button type="submit" className={`submit-btn${submittable ? ' active' : ''}`}>
                  {loading || passcodeValidating ? (
                    <CircularProgress size={21} />
                  ) : (
                    <React.Fragment>
                      {customConfig.submitButtonText ? (
                        <span>
                          {getMultiLocaleContent(customConfig.submitButtonText, i18n.language)}
                        </span>
                      ) : (
                        <span>{t('passcode.enter')}</span>
                      )}
                    </React.Fragment>
                  )}
                </button>
              </div>
            </form>
          )}
          <div className="remarks">
            {customConfig.remarks.length > 0 ? (
              customConfig.remarks.map((remark, index) => {
                return (
                  <div
                    className="remark"
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: getMultiLocaleContent(remark, i18n.language),
                    }}
                  ></div>
                );
              })
            ) : (
              <div className="remark">{t('passcode.remark')}</div>
            )}
          </div>
        </div>
        {customConfig.mobileBottomBanner && (
          <div className="mobile-bottom-banner">
            <img src={customConfig.mobileBottomBanner} alt="banner"></img>
          </div>
        )}
      </div>
      <div className="footer">
        {!customConfig.hideFooter && (
          <React.Fragment>
            {customConfig.footerImageUrl || customConfig.footerText ? (
              <a href={customConfig.footerLink} className="link">
                {customConfig.footerImageUrl && (
                  <img className="logo" src={customConfig.footerImageUrl} alt="Logo"></img>
                )}
                {customConfig.footerText && (
                  <span className="text">
                    {getMultiLocaleContent(customConfig.footerText, i18n.language)}
                  </span>
                )}
              </a>
            ) : customConfig.hideFooterLink ? (
              <div className="link">
                <img className="logo" src={logo} alt="Logo"></img>
                <span className="text">{t('app.footer')}</span>
              </div>
            ) : (
              <a href="https://www.oneclickgolive.com" className="link">
                <img className="logo" src={logo} alt="Logo"></img>
                <span className="text">{t('app.footer')}</span>
              </a>
            )}
          </React.Fragment>
        )}
        {process.env.REACT_APP_IS_CN === '1' && (
          <div className="icp">
            <a href="http://beian.miit.gov.cn/" rel="noreferrer" target="_blank">
              京ICP备16066699号-2
            </a>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051708"
              rel="noreferrer"
              target="_blank"
            >
              <img src={beianLogo} style={{ height: '20px', width: '20px' }} alt="beianLogo" />
              京公网安备 11010502051708号
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
