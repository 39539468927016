const translation = {
  general: {
    minute_one: '{{ count }}分鐘',
    minute_other: '{{ count }}分鐘',
    hour_one: '{{ count }}小時',
    hour_other: '{{ count }}小時',
  },
  app: {
    exit: '離開',
    close: '關閉',
    footer: 'Powered by One Click Go Live Limited',
    'login-btn': '登入',
    'logout-btn': '登出',
    confirm: '確認',
  },
  login: {
    title: '用户登入',
    'email-label': '輸入電郵',
    'not-registered-remark': '如還未有註冊用戶，請輸入電郵地址並點擊以下「確認」按鈕',
    'otp-guide-title': '驗證碼已發送',
    'otp-label': '輸入您的一次性驗證碼',
    'otp-remark-1': '我們已將您的一次性驗證碼發送到以下電郵地址',
    'otp-remark-2': '請於 15 分鐘內完成登入步驟',
    'next-step': '下一步',
    'email-error': '電郵不正確，請重新登入',
    'unknown-error': '錯誤，請重新登入',
    'network-error': '偵測到網路連線問題。如果問題持續,請嘗試使用其他網路。',
    'otp-error': '驗證碼不正確，請重新輸入',
    'otp-fatal-error': '驗證碼超時或驗證碼錯誤超過3次，請重新登入',
  },
  home: {
    title: '首頁',
  },
  event: {
    info: '詳情',
    'q-and-a': '問答',
    question: {
      placeholder: '設有問答環節，如您有任何提問，請在這裡輸入。',
      submit: '提交',
      'succeed-message': '感謝您的提問',
      'failed-message': '提交失敗，請稍後再試',
      'exceeds-char-limit-message': '最多可以輸入100個中文字（200字符）',
    },
    chatroom: '聊天室',
    polling: '投票',
    schedule: '時間表',
    si: {
      english: '英語',
      cantonese: '粵語',
      putonghua: '普通話',
    },
    playback: {
      'error-title': '播放失敗',
      'error-message': '也許您的網絡不太穩定，或者您並沒有權限觀看此視頻',
    },
    'just-started': '剛剛開始',
    'started-ago': '{{age}}前開始',
    'concurrent-error-message': '您已經在其他設備登入',
    'connection-error-message': '網絡不穩定',
    'require-login-message': '如果您有權限觀看此視頻，請登入',
    'session-kicked-error-message': '您已經在其他設備登入，點擊以下按鈕在這設備觀看',
    'view-here': '在此觀看',
    unauthorized: '未授權觀看',
  },
  passcode: {
    label: '請輸入密碼',
    enter: '確定',
    remark: '如未取得密碼，請聯絡您的活動主辦單位查詢',
    error: '密碼不正確',
    'select-placeholder': '請選擇',
  },
  register: {
    'step-1-label': '活動資訊',
    'step-2-label': '填寫 RSVP 表格',
    'step-3-label': '確認',
    'step-4-label': '完成',
    'register-finish-title': '完成！您的註冊已成功。',
    'register-finish-desc-1': '請在登記時出示以下 QR 碼',
    'register-finish-desc-2': '您可以截圖或下載圖片',
    'confirm-your-registration': '確認您的註冊',
    'label-download-image': '下載圖片',
    'label-join-now': '立即註冊',
    'label-back': '返回',
    'label-submit': '提交',
    'label-confirm': '確認',
    'label-region': '區域',
    'label-required-field': '* 表示必填欄位',
    'label-register-form': '註冊表格',
    'error-fail-download-image': '無法下載圖片。',
    'error-email-registered': '此電子郵件地址已經註冊。',
    'error-fail-register': '提交表單失敗。請稍後再試。',
    'error-fail-email-not-found': '提交表單失敗。找不到電子郵件欄位。',
  },
};

export default translation;
