import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setCustomization,
  setRequireLogin as reduxSetRequireLogin,
  setRequirePasscode,
  setRequirePurchase as reduxSetRequirePurchase,
} from 'redux/eventSlice';
import { setGlobalLoading } from 'redux/appSlice';
import { configGAUA } from 'redux/gaUaSlice';
import {
  updatePlaybackLogsCoreInfo,
  updatePlaybackLogsUserInfo,
  enableSendPlaybackEventsByConfig,
} from 'redux/playbackLogsSlice';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DialogContext } from 'components/dialogContext';
import * as API from 'api/baseAPI';
import * as cookiesUtils from 'utils/cookiesUtils';
import { getUserAgent } from 'utils/uaUtils';
import { getMultiLocaleContent, replaceAssetDomain } from 'utils/helper';
import logger from 'utils/loggerUtils';

import banner from 'images/banner.jpg';
import startFlip from 'images/start.jpg';
import endFlip from 'images/end.jpg';

const defaultCustomConfig = {
  /* defaultTab: null, */
  manualTriggerControl: false,
  pollingThemeColor: '#3fbb9b',
  questionBoxPlaceholder: null,
  enableCasting: false,
  selectChannelAtBottom: false,
  questionBoxAtBottom: false,
  banner: null,
  startFlip: null,
  endFlip: null,
  forcePlayer: null,
  channelSelectAlwaysOn: false,
};

export const useEventManipulate = (props) => {
  const { eventValidatedHandler, exitValidatedSession } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [initialized, setInitialized] = useState(false);
  const [initializedEvent, setInitializedEvent] = useState(false);
  const [channel, setChannel] = useState({});
  const vodStatusRef = useRef(null);
  const [passcodeEnabled, setPasscodeEnabled] = useState(false);
  const [passcodeValidateInterval, setPasscodeValidateInterval] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [passcodeToken, setPasscodeToken] = useState(null);
  // const [passcodeMetadata, setPasscodeMetadata] = useState(null)
  const [passcodeValidating, setPasscodeValidating] = useState(false);
  const [consecutiveFailedCount, setConsecutiveFailedCount] = useState(0);
  const [now, setNow] = useState();
  const [channelId, setChannelId] = useState(null);
  const [event, setEvent] = useState({});
  const [channels, setChannels] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [selectedTab, setSelectedTab] = useState('info');
  const customization = useSelector((state) => state.event.customization);
  const [customStyle, setCustomStyle] = useState({});
  const [customConfig, setCustomConfig] = useState(defaultCustomConfig);
  const [playerHasProblem, setPlayerHasProblem] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const [question, setQuestion] = useState('');
  const [questionSubmitting, setQuestionSubmitting] = useState(false);
  const [requireLogin, setRequireLogin] = useState(false);
  const [loginValidated, setLoginValidated] = useState(false);
  const [validLoginToken, setValidLoginToken] = useState(null);
  const [requirePurchase, setRequirePurchase] = useState(false);
  const [sessionKicked, setSessionKicked] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [containSchedule, setContainSchedule] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleStyle, setScheduleStyle] = useState({});

  const updateChannel = useCallback((newChannel) => {
    let _hlsPlaybackUrl = null;
    let _dashPlaybackUrl = null;
    if (newChannel.playbackUrl) {
      _hlsPlaybackUrl = newChannel.playbackUrl;
    }
    if (newChannel.dashPlaybackUrl) {
      _dashPlaybackUrl = newChannel.dashPlaybackUrl;
    }
    setChannel((s) => ({
      ...s,
      id: newChannel.id,
      label: newChannel.label,
      staticStream: newChannel.staticStream,
      stopInstantly: newChannel.stopInstantly,
      status: newChannel.status,
      vodStatus: newChannel.vodStatus,
      lowLatency: newChannel.lowLatency,
      drm: newChannel.drm,
      actualStartDatetime: newChannel.actualStartDatetime,
      actualEndDatetime: newChannel.actualEndDatetime,
      hlsPlaybackUrl: _hlsPlaybackUrl,
      dashPlaybackUrl: _dashPlaybackUrl,
    }));
    if (vodStatusRef.current === null && newChannel.vodStatus) {
      vodStatusRef.current = newChannel.vodStatus;
    }
  }, []);

  const updateEvent = useCallback(
    (newEvent) => {
      if (newEvent.customization) {
        dispatch(setCustomization(newEvent.customization));
      } else {
        dispatch(setCustomization({}));
      }
      setEvent(newEvent);
      setChannels(newEvent.channels);
    },
    [dispatch]
  );

  const tabChangeHandler = (e, newValue) => {
    if (newValue) {
      setSelectedTab(newValue);
    }
  };

  const title = () => {
    if (event && event.title) {
      return getMultiLocaleContent(event.title, i18n.language);
    }
    return '';
  };

  const description = () => {
    if (event && event.description) {
      return getMultiLocaleContent(event.description, i18n.language);
    }
    return '';
  };

  const detailDescription = () => {
    if (event && event.detailDescription) {
      return getMultiLocaleContent(event.detailDescription, i18n.language);
    }
    return '';
  };

  const questionEnabled = () => {
    if (event && event.question && event.question.enable) {
      return event.question.enable === true;
    }
    return false;
  };

  const isHLQuestion = () => {
    if (event && event.question && event.question.isHL) {
      return event.question.isHL === true;
    }
    return false;
  };

  const slidoQuestionLink = () => {
    if (event && event.question && event.question.slidoLink) {
      let locale = 'en_US';
      const localeMapping = {
        en: 'en_US',
        'zh-Hans': 'zh_hans',
        'zh-Hant': 'zh_hant',
      };
      let slidoQueryParam = '';
      if (i18n.language) {
        locale = localeMapping[i18n.language];
      }
      if (passcodeEnabled && passcode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${passcode}`;
      }
      /* if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.username) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.passcodeMetadata.username}`
      } else if (this.validatePasscodeResult && this.validatePasscodeResult.passcode && this.hasPasscode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.validatePasscodeResult.passcode}`
      }
      if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.emailaddress) {
        slidoQueryParam = `${slidoQueryParam}&user_email=${this.passcodeMetadata.emailaddress}`
      } */

      return `${event.question.slidoLink}/questions?lang=${locale}${slidoQueryParam}`;
    }
    return null;
  };

  const questionSubmitHandler = (e) => {
    e.preventDefault();
    if (questionSubmitting) {
      return;
    }
    if (question === '') {
      return;
    }
    if (question.length > 200) {
      openDialog(t('event.question.exceeds-char-limit-message'), () => {
        closeDialog();
      });
      return;
    }
    setQuestionSubmitting(true);

    let data = {
      text: question,
      eventId: params.eventId,
    };
    if (passcodeEnabled && passcode) {
      data['questioner'] = `${passcode}`;
    }

    let submitQuestion = isHLQuestion() ? API.submitHLQuestion : API.submitQuestion;

    submitQuestion(data)
      .then(() => {
        openDialog(t('event.question.succeed-message'), () => {
          closeDialog();
        });
        setQuestionSubmitting(false);
        setQuestion('');
      })
      .catch(() => {
        openDialog(t('event.question.failed-message'), () => {
          closeDialog();
        });
        setQuestionSubmitting(false);
      });
  };

  const questionChangeHandler = (e) => {
    setQuestion(e.target.value);
  };

  const chatroomEnabled = () => {
    if (event && event.chatroom && event.chatroom.enable) {
      return event.chatroom.enable === true;
    }
    return false;
  };

  const pollingEnabled = () => {
    if (event && event.polling && event.polling.enable) {
      return event.polling.enable === true;
    }
    return false;
  };

  const isHLPolling = () => {
    if (event && event.polling && event.polling.isHL) {
      return event.polling.isHL === true;
    }
    return false;
  };

  const parrotPollingLink = () => {
    if (event && event.polling) {
      if (passcodeEnabled && passcode) {
        // const voter = this.passcodeMetadata.email
        const voter = passcode;
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/polling?user=${voter}`;
      } else {
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/polling`;
      }
    }
  };

  const parrotChatroomLink = () => {
    if (event && event.polling) {
      if (passcodeEnabled && passcode) {
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/chatroom?user=${passcode}`;
      } else {
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/chatroom`;
      }
    }
  };

  const slidoPollingLink = () => {
    if (event && event.polling && event.polling.slidoLink) {
      let locale = 'en_US';
      const localeMapping = {
        en: 'en_US',
        'zh-Hans': 'zh_hans',
        'zh-Hant': 'zh_hant',
      };
      let slidoQueryParam = '';
      if (i18n.language) {
        locale = localeMapping[i18n.language];
      }
      if (passcodeEnabled && passcode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${passcode}`;
      }
      /* if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.username) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.passcodeMetadata.username}`
      } else if (this.validatePasscodeResult && this.validatePasscodeResult.passcode && this.hasPasscode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.validatePasscodeResult.passcode}`
      }
      if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.emailaddress) {
        slidoQueryParam = `${slidoQueryParam}&user_email=${this.passcodeMetadata.emailaddress}`
      } */

      return `${event.polling.slidoLink}/polls?lang=${locale}${slidoQueryParam}`;
    }
    return null;
  };

  const backgroundImage = () => {
    let defaultBanner = banner;
    let defaultStart = startFlip;
    let defaultEnd = endFlip;
    let customBanner = customConfig.banner;
    let customEnd = customConfig.endFlip;
    let customStart = customConfig.startFlip;
    if (customBanner) {
      customBanner = replaceAssetDomain(customBanner);
      defaultBanner = customBanner;
      defaultStart = customBanner;
    }
    if (customStart) {
      customStart = replaceAssetDomain(customStart);
      defaultStart = customStart;
    }
    if (customEnd) {
      customEnd = replaceAssetDomain(customEnd);
      defaultEnd = customEnd;
    }
    if (channel) {
      if (channel.status === 'live' || channel.status === 'live_starting') {
        return defaultStart;
      } else if (channel.status === 'end') {
        return defaultEnd;
      }
      return defaultBanner;
    }
    return defaultBanner;
  };

  const isDrm = () => {
    return channel && channel.drm === true;
  };

  const audioChangeHandler = useCallback(
    (channelId) => {
      let _channel = channels.find((element) => element.id === channelId);
      API.getChannelV2(channelId, _channel.staticStream, passcodeToken)
        .then((response) => {
          if (response && response.data) {
            setChannelId(channelId);
            if (response.data.vodStatus) {
              vodStatusRef.current = response.data.vodStatus;
            }
            updateChannel(response.data);
            if (response.data.label) {
              if (searchParams.has('channel')) {
                searchParams.set('channel', response.data.label);
              } else {
                searchParams.append('channel', response.data.label);
              }
              setSearchParams(searchParams);
            }
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    },
    [channels, passcodeToken, searchParams, setSearchParams, updateChannel]
  );

  const isLive = useCallback(() => {
    if (channel.status === 'live') {
      return true;
    }
    return false;
  }, [channel.status]);

  const isVod = useCallback(() => {
    if (channel.vodStatus && channel.vodStatus === 'done' && vodStatusRef.current === 'done') {
      return true;
    }
    return false;
  }, [channel.vodStatus]);

  const displayPasscodePage = useCallback(() => {
    if (passcodeEnabled && !passcodeToken) {
      return true;
    }
    return false;
  }, [passcodeEnabled, passcodeToken]);

  const validatePasscodeSuccessHandler = useCallback(
    (validateResponseData, eventChannels, saveCookies) => {
      setPasscodeValidating(true);
      // TODO handle channel in a better way
      if (eventChannels) {
        let defaultChannel = eventChannels[0];
        if (searchParams.has('audio')) {
          let channelLabel = searchParams.get('audio');
          let _channel = eventChannels.find((channel) => channel.label === channelLabel);
          if (_channel) {
            defaultChannel = _channel;
          }
        }
        API.getChannelV2(defaultChannel.id, defaultChannel.staticStream, validateResponseData.token)
          .then((channelResponse) => {
            if (channelResponse && channelResponse.data) {
              setPasscode(validateResponseData.passcode);
              setPasscodeToken(validateResponseData.token);
              setChannelId(defaultChannel.id);
              updateChannel(channelResponse.data);
              dispatch(
                updatePlaybackLogsUserInfo({
                  userIdentifier: validateResponseData.passcode,
                  userSessionIdentifier: validateResponseData.token,
                })
              );
              if (saveCookies) {
                let expireDate = new Date();
                expireDate.setHours(expireDate.getHours() + 12);
                cookiesUtils.setCookie(
                  `PCT-${params.eventId}`,
                  validateResponseData.token,
                  true,
                  expireDate.toGMTString()
                );
              }
              if (validateResponseData.metadata) {
                // setPasscodeMetadata(data.metadata)
              }
            }
            setPasscodeValidating(false);
            dispatch(setGlobalLoading(false));
          })
          .catch((err) => {
            logger.error(err);
            setPasscodeValidating(false);
            dispatch(setGlobalLoading(false));
            // TODO enhance handling, will stay on passcode page without pop up
          });
      }
      eventValidatedHandler(true);
    },
    [params.eventId, eventValidatedHandler, searchParams, updateChannel, dispatch]
  );

  const kickPasscodeSession = useCallback(
    (message) => {
      // allow channel monitor to ignore ongoning api call
      // to fix cannot show passcode page after session kicked due to timing issue
      setChannelId(null);
      setChannel((s) => ({
        ...s,
        hlsPlaybackUrl: null,
        dashPlaybackUrl: null,
      }));
      cookiesUtils.deleteCookie(`PCT-${params.eventId}`);
      setPasscode(null);
      setPasscodeToken(null);
      eventValidatedHandler(false);
      dispatch(
        updatePlaybackLogsUserInfo({
          userIdentifier: null,
          userSessionIdentifier: null,
        })
      );
      if (message) {
        openDialog(message, () => {
          closeDialog();
        });
      }
    },
    [params.eventId, closeDialog, openDialog, eventValidatedHandler, dispatch]
  );

  const kickLoginSession = useCallback(
    (message) => {
      setLoginValidated(false);
      setValidLoginToken(null);
      setSessionKicked(true);
      dispatch(
        updatePlaybackLogsUserInfo({
          userIdentifier: null,
          userSessionIdentifier: null,
        })
      );
      if (message) {
        openDialog(message, () => {
          closeDialog();
        });
      }
    },
    [closeDialog, dispatch, openDialog]
  );

  useEffect(() => {
    if (consecutiveFailedCount >= 3 && passcodeEnabled && passcodeToken) {
      kickPasscodeSession(t('event.connection-error-message'));
      setConsecutiveFailedCount(0);
    }
  }, [consecutiveFailedCount, kickPasscodeSession, passcodeEnabled, passcodeToken, t]);

  useEffect(() => {
    let timer = null;
    let ignore = false;

    if (passcodeEnabled && passcodeValidateInterval && passcodeToken) {
      timer = setInterval(() => {
        const data = {
          token: passcodeToken,
          logAccess: false,
        };
        API.validatePasscode(params.eventId, data)
          .then(() => {
            setConsecutiveFailedCount(0);
          })
          .catch((err) => {
            if (!ignore) {
              if (err && err.response && err.response.status === 403) {
                setConsecutiveFailedCount(0);
                kickPasscodeSession(t('event.concurrent-error-message'));
              } else {
                setConsecutiveFailedCount((count) => count + 1);
              }
            }
          });
      }, passcodeValidateInterval * 1000);
    }
    return () => {
      ignore = true;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [
    passcodeEnabled,
    passcodeValidateInterval,
    passcodeToken,
    params.eventId,
    channel.status,
    kickPasscodeSession,
    t,
  ]);

  useEffect(() => {
    if (playerHasProblem) {
      let _channel = channels.find((element) => element.id === channelId);
      API.getChannelV2(channelId, _channel.staticStream, passcodeToken)
        .then((response) => {
          if (response && response.data) {
            updateChannel(response.data);
          }
          setPlayerHasProblem(false);
        })
        .catch((err) => {
          logger.error(err);
          setPlayerHasProblem(false);
        });
    }
  }, [playerHasProblem, channels, channelId, passcodeToken, updateChannel]);

  const playerProblemHandler = useCallback(() => {
    setPlayerHasProblem(true);
  }, []);

  const getDisplayMessage = (text, customText) => {
    if (customText) {
      if (i18n.language && customText[i18n.language]) {
        return customText[i18n.language];
      }
    }
    return text;
  };

  useEffect(() => {
    setChannelId(null);
    setChannel({});
    vodStatusRef.current = null;
    setPasscodeEnabled(false);
    setPasscodeValidateInterval(null);
    setPasscode(null);
    setPasscodeToken(null);
    setPasscodeValidating(false);
    setConsecutiveFailedCount(0);
    setEvent({});
    setChannels([]);
    setIsReady(false);
    // setCustomStyle({});
    // setCustomConfig(defaultCustomConfig);
    setPlayerHasProblem(false);
    setQuestion('');
    setQuestionSubmitting(false);
    setRequireLogin(false);
    setLoginValidated(false);
    setValidLoginToken(null);
    setRequirePurchase(false);
    setSessionKicked(false);
    setNotFound(false);
    setInitializedEvent(false);
    setInitialized(false);
    // setSchedule([]);
    // setScheduleStyle({});
    document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
    // dispatch(setCustomization({}));
    dispatch(setRequirePasscode(false));
    dispatch(reduxSetRequireLogin(false));
    dispatch(reduxSetRequirePurchase(false));
    dispatch(
      updatePlaybackLogsUserInfo({
        userIdentifier: null,
        userSessionIdentifier: null,
      })
    );
    eventValidatedHandler(false);
  }, [dispatch, eventValidatedHandler, params.eventId]);

  // init event
  useEffect(() => {
    if (!params.eventId) {
      return;
    }
    // prevent this useEffect triggered again
    if (initialized) {
      return;
    }
    setInitialized(true);
    dispatch(setGlobalLoading(true));

    logger.log('init event');
    API.getEventV2(params.eventId)
      .then((response) => {
        if (response && response.data) {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Event');
          }
          updateEvent(response.data);
          setInitializedEvent(true);
        }
      })
      .catch((err) => {
        logger.error(err);
        setNotFound(true);
        dispatch(setGlobalLoading(false));
      });
  }, [initialized, params.eventId, updateEvent, dispatch]);

  const validateLoginEmail = useCallback(() => {
    const data = {
      passcode: user.email,
      logAccess: true,
    };
    API.validatePasscode(params.eventId, data)
      .then((response) => {
        setValidLoginToken(response.data.token);
        setLoginValidated(true);
        dispatch(
          updatePlaybackLogsUserInfo({
            userIdentifier: user.id,
            userSessionIdentifier: response.data.token,
          })
        );
      })
      .catch((err) => {
        logger.error(err);
        dispatch(setGlobalLoading(false));
        // will show incorrect email message as logged in with invalid email
      });
  }, [dispatch, params.eventId, user]);

  // handle require login and user login
  useEffect(() => {
    if (requireLogin) {
      if (isLoggedIn) {
        validateLoginEmail();
      } else {
        setLoginValidated(false);
        setValidLoginToken(null);
        dispatch(setGlobalLoading(false));
      }
    }
  }, [dispatch, isLoggedIn, requireLogin, validateLoginEmail]);

  useEffect(() => {
    if (requirePurchase) {
      openDialog(t('event.unauthorized'), () => {
        closeDialog();
      });
      dispatch(setGlobalLoading(false));
    }
  }, [closeDialog, dispatch, openDialog, requirePurchase, t]);

  // handle require login and user valid
  useEffect(() => {
    if (requireLogin) {
      if (loginValidated) {
        if (channels && channels[0]) {
          let defaultChannel = channels[0];
          API.getChannelV2(defaultChannel.id, defaultChannel.staticStream, false)
            .then((channelResponse) => {
              if (channelResponse && channelResponse.data) {
                setChannelId(defaultChannel.id);
                updateChannel(channelResponse.data);
              }
              dispatch(setGlobalLoading(false));
            })
            .catch((err) => {
              logger.error(err);
              dispatch(setGlobalLoading(false));
              // TODO enhance handling, will stay idle forever
            });
        }
      } else {
        setChannelId(null);
        setChannel({});
      }
    }
  }, [dispatch, loginValidated, requireLogin, channels, updateChannel]);

  // to validate single session for require login event
  useEffect(() => {
    let timer = null;
    let ignore = false;
    if (validLoginToken) {
      timer = setInterval(() => {
        const data = {
          token: validLoginToken,
          logAccess: false,
        };
        API.validatePasscode(params.eventId, data)
          .then(() => {
            setConsecutiveFailedCount(0);
          })
          .catch((err) => {
            if (!ignore) {
              if (err && err.response && err.response.status === 403) {
                setConsecutiveFailedCount(0);
                kickLoginSession(t('event.concurrent-error-message'));
              } else {
                setConsecutiveFailedCount((count) => count + 1);
              }
            }
          });
      }, 60 * 1000);
    }
    return () => {
      ignore = true;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [kickLoginSession, params.eventId, t, validLoginToken]);

  useEffect(() => {
    if (event && !initializedEvent) {
      let _event = event;
      let _passcodeEnabled = false;
      let _requireLogin = _event.login ? _event.login.enable === true : false;
      let _requirePurchase = _event.payment ? _event.payment.priceInCents > 0 : false;
      if (_event && _event.passcode && _event.passcode.enable) {
        _passcodeEnabled = true;
        if (_event.passcode.validateInterval) {
          setPasscodeValidateInterval(_event.passcode.validateInterval);
        }
      } else {
        _passcodeEnabled = false;
      }
      setPasscodeEnabled(_passcodeEnabled);
      dispatch(setRequirePasscode(_passcodeEnabled));
      if (_passcodeEnabled) {
        const token = cookiesUtils.readCookie(`PCT-${params.eventId}`);
        if (token) {
          if (searchParams.has('token')) {
            // let passcode page handle
            cookiesUtils.deleteCookie(`PCT-${params.eventId}`);
            dispatch(setGlobalLoading(false));
          } else {
            const data = {
              token: token,
              logAccess: true,
            };
            API.validatePasscode(params.eventId, data)
              .then((validateResponse) => {
                validatePasscodeSuccessHandler(validateResponse.data, _event.channels);
              })
              .catch((err) => {
                logger.error(err);
                dispatch(setGlobalLoading(false));
                // will show passcode page as not inputted passcode yet
              });
          }
        } else {
          dispatch(setGlobalLoading(false));
        }
      } else if (_requireLogin) {
        // handled by other useEffect
        // dispatch(setGlobalLoading(false))
        dispatch(reduxSetRequireLogin(true));
        setRequireLogin(true);
      } else if (_requirePurchase) {
        // handled by other useEffect
        dispatch(reduxSetRequirePurchase(true));
        setRequirePurchase(true);
      } else {
        // TODO handle channel in a better way
        if (_event.channels) {
          let defaultChannel = _event.channels[0];
          if (searchParams.has('channel')) {
            let channelLabel = searchParams.get('channel');
            let _channel = _event.channels.find((channel) => channel.label === channelLabel);
            if (_channel) {
              defaultChannel = _channel;
            }
          }
          API.getChannelV2(defaultChannel.id, defaultChannel.staticStream)
            .then((channelResponse) => {
              if (channelResponse && channelResponse.data) {
                setChannelId(defaultChannel.id);
                updateChannel(channelResponse.data);
              }
              dispatch(setGlobalLoading(false));
            })
            .catch((err) => {
              // cannot get stream json when
              logger.error(err);
              dispatch(setGlobalLoading(false));
              // TODO enhance handling, will stay idle forever
            });
        }
      }
    }
  }, [
    dispatch,
    event,
    initializedEvent,
    params.eventId,
    searchParams,
    updateChannel,
    validatePasscodeSuccessHandler,
  ]);

  // cleanup when leave the page
  useEffect(() => {
    return () => {
      document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      dispatch(setCustomization({}));
      dispatch(setRequirePasscode(false));
      dispatch(reduxSetRequireLogin(false));
      dispatch(reduxSetRequirePurchase(false));
      dispatch(
        updatePlaybackLogsUserInfo({
          userIdentifier: null,
          userSessionIdentifier: null,
        })
      );
      eventValidatedHandler(false);
    };
  }, [dispatch, eventValidatedHandler]);

  // handle "exit" button clicked
  useEffect(() => {
    if (exitValidatedSession) {
      kickPasscodeSession();
    }
  }, [params.eventId, exitValidatedSession, kickPasscodeSession]);

  // for create channel monitor
  useEffect(() => {
    let channelMonitor = null;
    let ignored = false;
    if (channelId) {
      logger.log('initial monitor');
      channelMonitor = setInterval(() => {
        setNow(Date.now());
        if ((channel.status !== 'live' && channel.status !== 'end') || channel.stopInstantly) {
          let _channel = channels.find((element) => element.id === channelId);
          API.getChannelV2(channelId, _channel.staticStream, passcodeToken)
            .then((response) => {
              if (response && response.data && !ignored) {
                updateChannel(response.data);
              }
              setConsecutiveFailedCount(0);
            })
            .catch((err) => {
              logger.error(err);
              if (passcodeEnabled && passcodeToken) {
                if (err && err.response && err.response.status === 403) {
                  setConsecutiveFailedCount(0);
                  kickPasscodeSession(t('event.concurrent-error-message'));
                } else if (passcodeValidateInterval) {
                  // not kick user for non 403 fail to get channel
                  // setConsecutiveFailedCount((count) => count + 1)
                }
              }
            });
        }
      }, parseInt(process.env.REACT_APP_EVENT_POLLING_INTERVAL_SECOND) * 1000);
    }

    return () => {
      ignored = true;
      if (channelMonitor !== null) {
        logger.log('destroy monitor');
        clearInterval(channelMonitor);
      }
    };
  }, [
    params.eventId,
    channelId,
    channel.stopInstantly,
    channel.status,
    channels,
    updateChannel,
    passcodeToken,
    kickPasscodeSession,
    passcodeEnabled,
    passcodeValidateInterval,
    t,
  ]);

  useEffect(() => {
    logger.log('check is ready');
    if (isLive() || isVod()) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [isLive, isVod]);

  const updatePlaybackLogsInfo = useCallback(() => {
    dispatch(
      updatePlaybackLogsCoreInfo({
        eventId: params.eventId,
        userAgent: getUserAgent(),
        isVod: isVod(),
        isLive: isLive(),
      })
    );
    dispatch(
      configGAUA({
        eventId: params.eventId,
        isVod: isVod(),
      })
    );
  }, [params.eventId, dispatch, isLive, isVod]);

  const liveDuration = () => {
    let durationInSecond = 0;
    if (channel.actualStartDatetime) {
      const _now = dayjs(now);
      const _startDatetime = dayjs(channel.actualStartDatetime);
      durationInSecond = _now.diff(_startDatetime, 'second');
    }
    if (durationInSecond < 60) {
      return t('event.just-started');
    } else if (durationInSecond < 3600) {
      return t('event.started-ago', {
        age: t('general.minute', { count: Math.round(durationInSecond / 60) }),
      });
    } else {
      return t('event.started-ago', {
        age: t('general.hour', { count: Math.round(durationInSecond / 3600) }),
      });
    }
  };

  useEffect(() => {
    updatePlaybackLogsInfo();
  }, [isReady, updatePlaybackLogsInfo]);

  useEffect(() => {
    setCustomStyle({});
    setCustomConfig({
      /* defaultTab: null, */
      manualTriggerControl: false,
      pollingThemeColor: '#3fbb9b',
      questionBoxPlaceholder: null,
      enableCasting: false,
      selectChannelAtBottom: false,
      questionBoxAtBottom: false,
      banner: null,
      startFlip: null,
      endFlip: null,
    });
    if (customization.event) {
      if (customization.event.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.event.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
      if (customization.event.config) {
        setCustomConfig({
          ...defaultCustomConfig,
          ...customization.event.config,
        });
        if (customization.event.config.defaultTab) {
          setSelectedTab(customization.event.config.defaultTab);
        }
        // rollout config related
        if (customization.event.config.rollout) {
          dispatch(
            enableSendPlaybackEventsByConfig(customization.event.config.rollout.sendPlaybackEvents)
          );
        }
      }
    }
    if (customization.schedule) {
      setContainSchedule(true);
      API.getSchedule(customization.schedule)
        .then((response) => {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Schedule');
          }
          setSchedule(response.data);
        })
        .catch((err) => {
          logger.error(err);
          setSchedule([]);
          setContainSchedule(false);
        });
    } else {
      setSchedule([]);
      setContainSchedule(false);
    }
    if (customization.scheduleStyle) {
      setScheduleStyle(customization.scheduleStyle);
    } else {
      setScheduleStyle({});
    }
  }, [customization, dispatch]);

  useEffect(() => {
    if (event.title) {
      const title = getMultiLocaleContent(event.title, i18n.language);
      if (title !== '') {
        document.title = `${title} | ${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE}`;
      } else {
        document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      }
    }
  }, [event.title, i18n.language]);

  return {
    backgroundImage,
    isReady,
    isDrm,
    isLive,
    isVod,
    lowLatency: channel.lowLatency,
    playerProblemHandler,
    channelId,
    event,
    channel,
    title,
    description,
    detailDescription,
    questionEnabled,
    isHLQuestion,
    slidoQuestionLink,
    question,
    setQuestion,
    questionSubmitHandler,
    questionChangeHandler,
    questionSubmitting,
    chatroomEnabled,
    pollingEnabled,
    isHLPolling,
    parrotPollingLink,
    parrotChatroomLink,
    slidoPollingLink,
    channels,
    customStyle,
    customConfig,
    selectedTab,
    tabChangeHandler,
    displayPasscodePage,
    passcode,
    passcodeEnabled,
    validatePasscodeSuccessHandler,
    passcodeValidating,
    audioChangeHandler,
    getDisplayMessage,
    liveDuration,
    requireLogin,
    loginValidated,
    sessionKicked,
    validateLoginEmail,
    notFound,
    containSchedule,
    schedule,
    scheduleStyle,
  };
};
