import React, { useEffect, useRef, useState } from 'react';
import 'theoplayer/ui.css';
import 'styles/scss/components/theoPlayer.scss';
import logger from 'utils/loggerUtils';
import { monitorTheoEvents } from './playerEvents';
import { postMessageToParent } from 'utils/helper';
import { setLatencyStat, setPlayingDate } from 'redux/hlsjsStatSlice';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { TheoTestStreamUtils, seekToLatestForLive } from './theoPlayerUtils';
import { addLatencyLog } from 'redux/playbackLogsSlice';
import useVisibilityChange from 'hook/useVisibilityChange';

export default function TheoPlayer(props) {
  const {
    options,
    playerStateCheckHandler,
    playerErrorHandler,
    playerStalledHandler,
    userActiveHandler,
    userInactiveHandler,
    embedHeaderComponentHtml,
    audioChangeHandler,
  } = props;
  const _el = useRef(null);
  const playerRef = useRef(null);
  const isVisibleRef = useRef(false);
  const sourceRef = useRef(options.src);
  const dispatch = useDispatch();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const documentVisible = useVisibilityChange();

  useEffect(() => {
    isVisibleRef.current = documentVisible;
    logger.info('[documentVisible]', documentVisible);
    if (playerRef.current?.muted && documentVisible) {
      seekToLatestForLive(playerRef.current);
    }
  }, [documentVisible]);

  useEffect(() => {
    if (playerRef.current && options.src && options.src !== sourceRef.current) {
      let source = {};
      if (options.srcType === 'hls') {
        source = {
          sources: [
            {
              src: options.src,
              type: 'application/x-mpegurl',
            },
          ],
        };
      } else {
        source = {
          sources: [
            {
              src: options.src,
              type: 'application/dash+xml',
            },
          ],
        };
      }
      playerRef.current.source = source;
    }
    sourceRef.current = options.src;
  }, [options.src, options.srcType]);

  useEffect(() => {
    logger.log('theo mount');
    let monitor = null;
    let statMonitor = null;
    let playingDateMonitor = null;
    let theoTestStreamUtils = null;
    let previousProgress = 0;
    const errorHandler = () => {
      playerErrorHandler();
      logger.info('error handle');
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
      if (monitor !== null) {
        clearInterval(monitor);
      }
    };
    const controlBarMonitor = new MutationObserver((mutationList) => {
      for (let i = 0; i < mutationList.length; i++) {
        const el = mutationList[i].target;
        const controlBarVisible =
          (el.classList.contains('vjs-user-active') || el.classList.contains('vjs-paused')) &&
          el.classList.contains('vjs-has-started');
        if (controlBarVisible) {
          if (userActiveHandler) {
            userActiveHandler();
          }
        } else {
          if (userInactiveHandler) {
            userInactiveHandler();
          }
        }
      }
    });

    if (_el.current) {
      import(/* webpackChunkName: "theoplayer" */ 'theoplayer').then((THEOplayer) => {
        playerRef.current = new THEOplayer.Player(_el.current, {
          libraryLocation: `/static/js/theoplayer4-10-0`,
          // TODO Uncomment the line below, and set its value to your THEOplayer license
          license: `${process.env.REACT_APP_THEO_PLAYER_LICENSE}`,
          mutedAutoplay: 'all',
          allowNativeFullscreen: true,
        });
        playerRef.current.autoplay = true;

        theoTestStreamUtils = new TheoTestStreamUtils(playerRef.current);
        // following hlsjsPlayer.js#184 : const latencyOffset = 2500;
        statMonitor = setInterval(() => {
          if (!playerRef.current || !theoTestStreamUtils) {
            return;
          }
          const latencyOffset = 2500;
          const latency = latencyOffset + parseInt(theoTestStreamUtils.calculateLatency());
          dispatch(
            setLatencyStat({
              forwardBufferLength: parseInt(1000 * theoTestStreamUtils.calculateBufferSize()),
              latency,
              playbackRate: playerRef.current.playbackRate,
              curBitrate: parseInt(theoTestStreamUtils.calculatePlayedBandwidth() / 1000),
            })
          );
          if (!playerRef.current.muted || isVisibleRef.current) {
            dispatch(
              addLatencyLog({
                latency,
                time: theoTestStreamUtils.calculateCurrentTimestamp().toISOString(),
              })
            );
          }
        }, 1000);
        playingDateMonitor = setInterval(() => {
          if (!theoTestStreamUtils || !theoTestStreamUtils.calculateCurrentProgramDt()) {
            return;
          }
          dispatch(
            setPlayingDate(dayjs(theoTestStreamUtils.calculateCurrentProgramDt()).toISOString())
          );
        }, 100);

        let source = null;
        if (options.srcType === 'hls') {
          let src = {
            src: sourceRef.current,
            type: 'application/x-mpegurl',
          };

          if (options.isDrm) {
            let drmConfiguration = {
              integration: 'axinom',
              token: options.drmConfig.drmToken,
              fairplay: {
                licenseAcquisitionURL: options.drmConfig.fairPlayLicenseAcquisitionUrl,
                certificateURL: options.drmConfig.fairPlayCertificateUrl,
              },
            };
            src.contentProtection = drmConfiguration;
          }

          source = {
            sources: src,
          };
        } else {
          let src = {
            src: sourceRef.current,
            type: 'application/dash+xml',
          };

          if (options.isDrm) {
            let drmConfiguration = {
              integration: 'axinom',
              token: options.drmConfig.drmToken,
              playready: {
                licenseAcquisitionURL: options.drmConfig.playreadyLicenseAcquisitionUrl,
              },
              widevine: {
                licenseAcquisitionURL: options.drmConfig.widevineLicenseAcquisitionUrl,
              },
            };
            src.contentProtection = drmConfiguration;
          }

          source = {
            sources: src,
          };
        }

        monitorTheoEvents(playerRef.current, THEOplayer.version, Date.now());

        playerRef.current.source = source;
        playerRef.current.addEventListener('play', () => {
          logger.log('play');
        });

        playerRef.current.addEventListener('error', function (event) {
          logger.error(event);
          errorHandler();
        });

        playerRef.current.addEventListener('ended', function (event) {
          logger.log('ended');
          if (!options.isVod) {
            errorHandler();
          }
        });

        const theoPlayerContainer = document.querySelector('.video-js');
        controlBarMonitor.observe(theoPlayerContainer, {
          attributes: true,
          attributeFilter: ['class'],
          childList: false,
          characterData: false,
        });

        /*
        playerRef.current.addEventListener('progress', function (event) {
          console.log(playerRef.current.seekable.start(0));
          console.log(playerRef.current.seekable.end(0));
          console.log(playerRef.current.duration);
          console.log(playerRef.current.currentTime);
          console.log(playerRef.current.seekable);
          console.log(playerRef.current.seekable.length - 1);
        });
        */

        playerRef.current.addEventListener('presentationmodechange', function (event) {
          if (event.presentationMode === 'fullscreen') {
            setIsFullscreen(true);
          } else {
            setIsFullscreen(false);
          }
        });

        playerRef.current.addEventListener('durationchange', function (event) {
          seekToLatestForLive(playerRef.current);
        });

        if (monitor !== null) {
          clearInterval(monitor);
        }

        monitor = setInterval(() => {
          let currentProgress = 0;
          if (playerRef.current) {
            currentProgress = playerRef.current.currentTime;
            if (previousProgress !== currentProgress) {
              if (currentProgress !== 0 && previousProgress === 0) {
                // determine player started by time updated
                // this.$emit('instanceStarted')
              }
              playerStateCheckHandler({
                state: 'instancePlaying',
                previous: previousProgress,
                current: currentProgress,
              });
            } else {
              if (playerRef.current.paused) {
                playerStateCheckHandler({
                  state: 'instancePaused',
                  previous: previousProgress,
                  current: currentProgress,
                });
              } else {
                playerStalledHandler({ previous: previousProgress, current: currentProgress });
              }
            }
            previousProgress = currentProgress;
          } else {
            playerStalledHandler({ previous: previousProgress, current: currentProgress });
          }
        }, options.checkingInterval);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
      logger.log('theo unmount');
      if (monitor !== null) {
        clearInterval(monitor);
      }
      if (statMonitor) {
        clearInterval(statMonitor);
      }
      if (playingDateMonitor) {
        clearInterval(playingDateMonitor);
      }
      if (theoTestStreamUtils) {
        theoTestStreamUtils = null;
      }
      if (controlBarMonitor) {
        controlBarMonitor.disconnect();
      }
    };
  }, [
    dispatch,
    options.checkingInterval,
    options.drmConfig.drmToken,
    options.drmConfig.fairPlayCertificateUrl,
    options.drmConfig.fairPlayLicenseAcquisitionUrl,
    options.drmConfig.playreadyLicenseAcquisitionUrl,
    options.drmConfig.widevineLicenseAcquisitionUrl,
    options.isDrm,
    options.isVod,
    options.srcType,
    playerErrorHandler,
    playerStalledHandler,
    playerStateCheckHandler,
    userActiveHandler,
    userInactiveHandler,
  ]);

  useEffect(() => {
    const postMessageHandler = (e) => {
      if (e.data && e.data.action) {
        const action = e.data.action;
        if (playerRef.current) {
          switch (action) {
            case 'SEEK':
              if (options.isVod && !isNaN(e.data.value)) {
                const targetTime = e.data.value;
                if (playerRef.current.duration > targetTime) {
                  playerRef.current.currentTime = targetTime;
                }
              }
              break;
            case 'GET_CURRENT_POSITION':
              if (options.isVod) {
                const currentTime = playerRef.current.currentTime;
                postMessageToParent(
                  {
                    action: 'GET_CURRENT_POSITION',
                    value: currentTime,
                  },
                  e.origin === 'null' ? '*' : e.origin
                );
              }
              break;
            case 'GET_DURATION':
              if (options.isVod) {
                const duration = playerRef.current.duration;
                postMessageToParent(
                  {
                    action: 'GET_DURATION',
                    value: duration,
                  },
                  e.origin === 'null' ? '*' : e.origin
                );
              }
              break;
            case 'GET_PROGRAM_TIME':
              if (!options.isVod) {
                const currentTime = playerRef.current.currentProgramDateTime;
                postMessageToParent(
                  {
                    action: 'GET_PROGRAM_TIME',
                    value: currentTime ? currentTime.toISOString() : null,
                  },
                  e.origin === 'null' ? '*' : e.origin
                );
              }
              break;
            case 'PAUSE':
              playerRef.current.pause();
              break;
            case 'PLAY':
              playerRef.current.play();
              break;
            default:
              break;
          }
        }
      }
    };

    if (options.isEmbed) {
      window.addEventListener('message', postMessageHandler, false);
    }

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, [options.isEmbed, options.isVod]);

  useEffect(() => {
    if (isFullscreen) {
      if (embedHeaderComponentHtml) {
        _el.current.insertAdjacentHTML('beforeend', embedHeaderComponentHtml);
        document.getElementById('embed-audio-selector').addEventListener('change', (e) => {
          audioChangeHandler(e.target.value);
        });
      }
    }

    return () => {
      const elem = document.getElementById('embed-audio-selector-container');
      if (elem) {
        elem.remove();
      }
    };
  }, [audioChangeHandler, isFullscreen, embedHeaderComponentHtml]);

  return (
    <div
      // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
      className={`theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer${
        options.isEmbed ? ' embed' : ''
      }`}
      // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
      // Which is need by the player
      ref={_el}
    />
  );
}
