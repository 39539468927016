import { Auth } from 'aws-amplify';
import store from '../store';
import { logout } from '../redux/authSlice';
import logger from 'utils/loggerUtils';
import dayjs from 'dayjs';
import dayjsZh from 'dayjs/locale/zh';

export function getMultiLocaleContent(content, locale, fallbackToEn) {
  if (typeof content === 'string') {
    return content;
  }
  if (!content) {
    return '';
  }
  if (!content[locale] && fallbackToEn) {
    return content['en'] || '';
  }
  return content[locale] || '';
}

export const logoutHandler = async () => {
  try {
    await Auth.signOut();
    store.dispatch(logout());
  } catch (err) {
    logger.log(err);
  }
};

export const replaceAssetDomain = (url) => {
  return url.replace(/^\/assets\//, `${process.env.REACT_APP_ASSET_BASE_URL}/assets/`);
};

export const postMessageToParent = (data, origin) => {
  const parentWindow = window.parent;
  if (parentWindow) {
    parentWindow.postMessage(data, origin);
  }
};

const getTime = (input, locale) => {
  if (typeof input === 'string' || input instanceof String) return dayjs(input).locale(locale);
  return input;
};

export const getTimeRange = (record, language) => {
  let isChinese = language.startsWith('zh');
  let defaultLocale = 'en';
  const startTime = getTime(record?.estimatedStartDatetime, isChinese ? dayjsZh : defaultLocale);
  const endTime = getTime(record?.estimatedEndDatetime, isChinese ? dayjsZh : defaultLocale);

  let enDateFormat = 'ddd DD MMM YYYY, HH:mm';
  let zhDateFormat = 'YYYY年M月D日 ddd, HH:mm';
  let timeFormat = 'HH:mm';

  let startTimeFormat = enDateFormat;
  let endTimeFormat = enDateFormat;

  if (isChinese) {
    startTimeFormat = zhDateFormat;
    if (startTime.isSame(endTime, 'day')) {
      endTimeFormat = timeFormat;
    } else {
      endTimeFormat = zhDateFormat;
    }
  } else {
    if (startTime.isSame(endTime, 'day')) {
      endTimeFormat = timeFormat;
    }
  }

  return `${startTime.format(startTimeFormat)} - ${endTime.format(endTimeFormat)}`;
};
