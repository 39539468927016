import { Auth } from 'aws-amplify';
import axios from 'axios';

export const axiosInstance = axios.create({
  timeout: parseInt(process.env.REACT_APP_DEFAULT_API_TIMEOUT_SECOND) * 1000,
});

const axiosAuthInstance = axios.create({
  timeout: parseInt(process.env.REACT_APP_DEFAULT_API_TIMEOUT_SECOND) * 1000,
});

axiosAuthInstance.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((data) => {
        config.headers['Authorization'] = `Bearer ${data.getIdToken().getJwtToken()}`;
        resolve(config);
      })
      .catch((error) => {
        if (error && error.response) {
          error.response.status = 401;
        } else {
          error.response = {
            status: 401,
          };
        }
        reject(error);
      });
  });
});

export function getStream(eventId, isStatic, token) {
  if (isStatic) {
    return axiosInstance({
      method: 'get',
      baseURL: `${process.env.REACT_APP_STATIC_STREAM_BASE_URL}`,
      url: `/${eventId}`,
      timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
    });
  } else {
    if (token && token !== '') {
      return axiosInstance({
        method: 'get',
        baseURL: `${process.env.REACT_APP_STREAM_EVENT_BASE_URL}`,
        url: `/${eventId}`,
        headers: { 'X-Ocgl-Passcode-Token': token },
        timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
      })
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.event) {
            response.data = response.data.data.event;
            return Promise.resolve(response);
          } else {
            return Promise.reject('Invalid Json');
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    return axiosInstance({
      method: 'get',
      baseURL: `${process.env.REACT_APP_STREAM_EVENT_BASE_URL}`,
      url: `/${eventId}`,
      timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
    })
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.event) {
          response.data = response.data.data.event;
          return Promise.resolve(response);
        } else {
          return Promise.reject('Invalid Json');
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export function getChannelV2(eventId, isStatic, token) {
  if (isStatic) {
    return axiosInstance({
      method: 'get',
      baseURL: `${process.env.REACT_APP_V2_STATIC_STREAM_BASE_URL}`,
      url: `/${eventId}`,
      timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
    });
  } else {
    if (token && token !== '') {
      return axiosInstance({
        method: 'get',
        baseURL: `${process.env.REACT_APP_STREAM_EVENT_BASE_URL}`,
        url: `/${eventId}`,
        headers: { 'X-Ocgl-Passcode-Token': token },
        timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
      })
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.event) {
            response.data = response.data.data.event;
            return Promise.resolve(response);
          } else {
            return Promise.reject('Invalid Json');
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    return axiosInstance({
      method: 'get',
      baseURL: `${process.env.REACT_APP_STREAM_EVENT_BASE_URL}`,
      url: `/${eventId}`,
      timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
    })
      .then((response) => {
        if (response && response.data && response.data.data && response.data.data.event) {
          response.data = response.data.data.event;
          return Promise.resolve(response);
        } else {
          return Promise.reject('Invalid Json');
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export function getEvent(eventId) {
  return axiosInstance({
    method: 'get',
    baseURL: `${process.env.REACT_APP_EVENT_BASE_URL}`,
    url: `/${eventId}`,
    timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
  });
}

export function getEventV2(eventId) {
  return axiosInstance({
    method: 'get',
    baseURL: `${process.env.REACT_APP_V2_EVENT_BASE_URL}`,
    url: `/${eventId}`,
    timeout: parseInt(process.env.REACT_APP_DEFAULT_EVENT_STREAM_TIMEOUT_SECOND) * 1000,
  });
}

export function sendPlaybackLogs(logs) {
  return axiosInstance({
    method: 'post',
    baseURL: `${process.env.REACT_APP_ANALYTICS_SERVICE_API_URL}`,
    url: '/playback-logs',
    data: {
      data: logs,
    },
  });
}

export function validatePasscode(eventId, data) {
  return axiosInstance({
    method: 'post',
    baseURL: `${process.env.REACT_APP_AUTHORIZATION_SERVICE_BASE_URL}`,
    url: `/${eventId}/validate-passcode`,
    data: data,
  });
}

export function submitQuestion({ text, eventId, questioner }) {
  let _questioner = questioner ? questioner : '';
  return axiosInstance({
    method: 'post',
    baseURL: `${process.env.REACT_APP_QA_BASE_URL}`,
    url: `/query`,
    data: {
      operationName: 'AddMessage',
      variables: {
        queueId: `${eventId}`,
        text: `${text}`,
        questioner: `${_questioner}`,
      },
      query:
        'mutation AddMessage($queueId: String!, $text: String!, $questioner: String) {postMessage(queueId: $queueId, text: $text, questioner: $questioner) {id text questioner createdAt category order __typename}}',
    },
  });
}

export function submitHLQuestion(data) {
  return axiosInstance({
    method: 'post',
    baseURL: `${process.env.REACT_APP_HL_QA_BASE_URL}`,
    url: `/questions`,
    data: data,
  });
}

export function authGetMe() {
  return axiosAuthInstance({
    method: 'get',
    baseURL: `${process.env.REACT_APP_ACCOUNT_SERVICE_BASE_URL}`,
    url: '/auth/me',
  });
}

export function getDrmToken() {
  return axiosInstance({
    method: 'get',
    baseURL: `${process.env.REACT_APP_DRM_TOKEN_BASE_URL}`,
    url: `/drm/token`,
  });
}

export function getSchedule(path) {
  return axiosInstance({
    method: 'get',
    baseURL: `${process.env.REACT_APP_SCHEDULE_BASE_URL}`,
    url: `${path}`,
  });
}

export function postUserRegister(eventId, email, data) {
  return axiosInstance({
    method: 'post',
    baseURL: `${process.env.REACT_APP_REGISTRATION_SERVICE_BASE_URL}`,
    url: `/${eventId}/register/email`,
    data: {
      email: email,
      metadata: {
        ...data,
      },
    },
  });
}
