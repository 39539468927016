import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LaunchIcon from '@mui/icons-material/Launch';
import PlaceIcon from '@mui/icons-material/Place';
import { Link } from '@mui/material';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';
import { useTranslation } from 'react-i18next';

const EventPreview = (props) => {
  const { record, onJoinClick, ...rest } = props;
  const { i18n, t } = useTranslation();

  //   console.log('defaultData', defaultData);
  //   console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;
  const websiteLink = rsvpData?.website;
  const email = rsvpData?.email;
  const contactNo = rsvpData?.contactNo;
  const title = getMultiLocaleContent(record?.title, i18n.language, true);

  const addressText = getMultiLocaleContent(rsvpData?.address?.text, i18n.language, true);
  const addressLat = rsvpData?.address?.lat;
  const addressLon = rsvpData?.address?.lon;
  const addressZoom = parseFloat(rsvpData?.address?.z ?? 10).toFixed(0);

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
        Preview
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography variant="h5" sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {getTimeRange(record, i18n.language)}
          </Typography>
          <Typography
            variant="subtitle1"
            id="previewInput_desc"
            dangerouslySetInnerHTML={{
              __html: getMultiLocaleContent(record?.detailDescription, i18n.language, true),
            }}
          />
          {addressLat && addressLon && (
            <Box
              id="previewInput_address_embedUrl"
              sx={{
                mt: 2,
                width: ['100%', '500px'],
                height: '300px',
                iframe: {
                  border: 0,
                  width: '100%',
                  height: '100%',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://maps.google.com/maps?q=${addressLat},${addressLon}&hl=${i18n.language}&z=${addressZoom}&output=embed"/>`,
              }}
            />
          )}
          {addressText && (
            <Box
              id="previewInput_address"
              sx={{ display: 'inline-flex', alignItems: 'top', my: 1 }}
            >
              <PlaceIcon sx={{ fontSize: '20px', mr: 1 }} />
              <Typography
                variant="subtitle2"
                sx={{ m: 0, whiteSpace: 'pre-line', wordBreak: 'break-word' }}
              >
                {addressText}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            {websiteLink && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1 }}>
                <LinkIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Link
                  href={websiteLink}
                  target="_blank"
                  color="inherit"
                  underline="hover"
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <Typography variant="subtitle2">{websiteLink}</Typography>
                  <LaunchIcon sx={{ fontSize: '15px', ml: 1 }} />
                </Link>
              </Box>
            )}

            {contactNo && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1 }}>
                <PhoneIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="subtitle2">{contactNo}</Typography>
              </Box>
            )}

            {email && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1 }}>
                <EmailIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Link href={`mailto:${email}`} color="inherit" underline="hover">
                  <Typography variant="subtitle2">{email}</Typography>
                </Link>
              </Box>
            )}

            <Button
              variant="outlined"
              onClick={() => onJoinClick()}
              sx={{
                ml: websiteLink || contactNo || email ? '' : 'auto',
                '& .MuiButton-startIcon': { marginRight: '0px' },
                mt: [3, 1],
                textTransform: 'none',
              }}
            >
              <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-join-now')}</Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EventPreview;
