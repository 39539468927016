import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';

const ReactHookCheckBox = (props) => {
  const { field, ...rest } = props;
  const { register, watch } = useFormContext();
  const checked = watch(field.key);
  return (
    <Checkbox
      {...rest}
      {...register(field.key)}
      name={field.key}
      required={field?.required}
      checked={checked}
    />
  );
};

const ConfirmButton = (props) => {
  const { onConfirmClick, ...rest } = props;
  const { t } = useTranslation();
  const formData = useWatch({});
  return (
    <Button
      {...rest}
      variant="contained"
      sx={{
        ml: 'auto',
        '& .MuiButton-startIcon': { marginRight: '0px' },
        width: ['100%', 'auto'],
        my: [2, 0],
        textTransform: 'none',
      }}
      onClick={() => {
        onConfirmClick(formData);
      }}
    >
      <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-confirm')}</Box>
    </Button>
  );
};

const RsvpDropDownInput = (props) => {
  const { field, label, formRegKey, ...rest } = props;
  const { register, getValues } = useFormContext();
  const { i18n } = useTranslation();
  const [value, setValue] = useState(
    getValues(formRegKey) ?? field?.options?.find((it) => it.key === field?.default?.[0])?.key
  );

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const displaylabel =
    label ??
    `${getMultiLocaleContent(field?.label, i18n.language, true) ?? formRegKey}${
      field?.required ? '*' : ''
    }`;

  return (
    <FormControl {...rest}>
      <InputLabel id={`select-${formRegKey}-label`}>{displaylabel}</InputLabel>
      <Select
        {...register(formRegKey)}
        name={formRegKey}
        size="small"
        labelId={`select-${formRegKey}-label`}
        id={`select-${formRegKey}`}
        value={value}
        label={displaylabel}
        onChange={handleChange}
      >
        {field?.options?.map((it) => {
          return (
            <MenuItem key={it.key} value={it.key}>
              {getMultiLocaleContent(it, i18n.language, true)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const RsvpFormPreview = (props) => {
  const { record, onBackClick, onSubmitClick, isConfirmMode, ...rest } = props;
  const { register } = useFormContext();
  const { i18n, t } = useTranslation();

  // console.log('defaultData', defaultData);
  // console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
        Preview
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          {isConfirmMode && (
            <Typography variant="h4" sx={{ my: 2, color: 'red', fontWeight: 700 }}>
              {t('register.confirm-your-registration')}
            </Typography>
          )}
          <Typography variant="h5">
            {getMultiLocaleContent(record?.title, i18n.language, true)}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {getTimeRange(record, i18n.language)}
          </Typography>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {t('register.label-register-form')}
          </Typography>
          <Typography
            id="previewInput_desc"
            variant="subtitle1"
            dangerouslySetInnerHTML={{
              __html: getMultiLocaleContent(rsvpData?.description, i18n.language, true),
            }}
          />

          {/* Dynamic Fields */}
          {/* Dynamic Fields */}
          {rsvpData?.fields?.map((field, index) => {
            if (field.type === 'select') {
              return (
                <RsvpDropDownInput
                  disabled={isConfirmMode}
                  formRegKey={field.key}
                  id={`previewInput_${index + 1}`}
                  key={`${index}-${field.id}`}
                  fullWidth
                  field={field}
                  size="small"
                  sx={{ my: 2 }}
                />
              );
            } else if (field.type === 'tel') {
              return (
                <Box
                  id={`previewInput_${index + 1}`}
                  key={`${index}-${field.id}`}
                  sx={{
                    display: 'flex',
                    flexDirection: ['column', 'row'],
                    alignItems: 'center',
                    my: 2,
                  }}
                >
                  <RsvpDropDownInput
                    disabled={isConfirmMode}
                    field={field}
                    sx={{ width: ['100%', '50%'] }}
                    label={t('register.label-region')}
                    formRegKey={`${field.key}Region`}
                  />
                  <TextField
                    disabled={isConfirmMode}
                    {...register(field.key)}
                    size="small"
                    fullWidth
                    type={field.type}
                    sx={{
                      width: ['100%', '50%'],
                      mt: [4, 0],
                      ml: [0, 2],
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    label={`${
                      getMultiLocaleContent(field?.label, i18n.language, true) ?? field.key
                    }${field.required ? '*' : ''}`}
                    margin="dense"
                    inputProps={{
                      required: field.required,
                    }}
                  />
                </Box>
              );
            } else if (field.type === 'checkbox') {
              return (
                <FormControlLabel
                  key={`${index}-${field.id}`}
                  sx={{ alignItems: 'flex-start' }}
                  control={
                    <ReactHookCheckBox field={field} disabled={isConfirmMode} sx={{ mt: 1 }} />
                  }
                  label={
                    <Box sx={{ display: 'inline-flex' }}>
                      <Box sx={{ mt: 2 }}>{field.required ? '*' : ''}</Box>
                      <Typography
                        id={`previewInput_${index + 1}`}
                        variant="subtitle1"
                        dangerouslySetInnerHTML={{
                          __html: getMultiLocaleContent(field?.description, i18n.language, true),
                        }}
                      />
                    </Box>
                  }
                />
              );
            } else {
              return (
                <TextField
                  disabled={isConfirmMode}
                  {...register(field.key)}
                  name={field.key}
                  id={`previewInput_${index + 1}`}
                  key={`${index}-${field.id}`}
                  type={field.type}
                  size="small"
                  sx={{ my: 2 }}
                  fullWidth
                  label={`${getMultiLocaleContent(field?.label, i18n.language, true) ?? field.key}${
                    field.required ? '*' : ''
                  }`}
                  margin="dense"
                  inputProps={{
                    required: field.required,
                    pattern: field.pattern,
                  }}
                />
              );
            }
          })}

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                '& .MuiButton-startIcon': { marginRight: '0px' },
                width: ['100%', 'auto'],
                textTransform: 'none',
              }}
              onClick={() => {
                onBackClick();
              }}
            >
              <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-back')}</Box>
            </Button>

            {!isConfirmMode && (
              <Button
                variant="contained"
                sx={{
                  '& .MuiButton-startIcon': { marginRight: '0px' },
                  width: ['100%', 'auto'],
                  my: [2, 0],
                  textTransform: 'none',
                }}
                onClick={() => {
                  onSubmitClick();
                }}
              >
                <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-submit')}</Box>
              </Button>
            )}
            {isConfirmMode && <ConfirmButton onConfirmClick={onSubmitClick} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RsvpFormPreview;
