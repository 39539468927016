import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Player from 'components/player';
import { useEventManipulate } from './useEventManipulate';
import { useParams } from 'react-router-dom';
import PasscodePage from 'components/passcodePage';
import NotFound from 'components/notFound';
import ReactDOMServer from 'react-dom/server';

import 'styles/scss/routes/embed.scss';
import { useSelector } from 'react-redux';
import { postMessageToParent } from 'utils/helper';

export default function Embed(props) {
  const params = useParams();
  const { browserSessionIdentifier } = useSelector((state) => state.app);
  const {
    backgroundImage,
    event,
    channel,
    channelId,
    channels,
    isReady,
    isDrm,
    isVod,
    lowLatency,
    customStyle,
    playerProblemHandler,
    displayPasscodePage,
    title,
    passcodeValidating,
    validatePasscodeSuccessHandler,
    audioChangeHandler,
    getDisplayMessage,
    customConfig,
    notFound,
  } = useEventManipulate(props);
  const statusRef = useRef(null);
  const [userActive, setUserActive] = useState(true);

  const userActiveHandler = useCallback(() => {
    setUserActive(true);
  }, []);

  const userInactiveHandler = useCallback(() => {
    if (!customConfig.channelSelectAlwaysOn) {
      setUserActive(false);
    }
  }, [customConfig.channelSelectAlwaysOn]);

  const wrappedValidatePasscodeSuccessHandler = useCallback(
    (validateResponseData, saveCookies) => {
      validatePasscodeSuccessHandler(validateResponseData, channels, saveCookies);
    },
    [channels, validatePasscodeSuccessHandler]
  );

  useEffect(() => {
    if (channel.status) {
      if (channel.status !== 'live' && statusRef.current === 'live') {
        postMessageToParent({ action: 'EVENT_LIVE_ENDED' }, '*');
      }

      statusRef.current = channel.status;
    }
  }, [channel.status]);

  const embedHeaderComponentHtml = useMemo(() => {
    const headerComponent = (
      <div className="active-header" id="embed-audio-selector-container">
        <div className="audio-selector-wrapper">
          <select
            className="audio-selector"
            value={channelId}
            onChange={(e) => {
              audioChangeHandler(e.target.value);
            }}
            id="embed-audio-selector"
          >
            {channels.map((_channel) => (
              <option value={_channel.id} key={_channel.id}>
                {getDisplayMessage(_channel.label, _channel.customLabel)}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
    if (channelId && channels && channels.length > 1 && userActive) {
      return ReactDOMServer.renderToString(headerComponent);
    } else {
      return null;
    }
  }, [audioChangeHandler, channelId, channels, getDisplayMessage, userActive]);

  return (
    <Fragment>
      {notFound ? (
        <NotFound />
      ) : (
        <main className="embed-page" style={customStyle}>
          {displayPasscodePage() ? (
            <PasscodePage
              eventId={params.eventId}
              title={title()}
              passcodeValidating={passcodeValidating}
              validatePasscodeSuccessHandler={wrappedValidatePasscodeSuccessHandler}
            ></PasscodePage>
          ) : (
            <div className="event-container">
              {channelId && channels && channels.length > 1 && userActive && (
                <div className="active-header">
                  <div className="audio-selector-wrapper">
                    <select
                      className="audio-selector"
                      value={channelId}
                      onChange={(e) => {
                        audioChangeHandler(e.target.value);
                      }}
                    >
                      {channels.map((_channel) => (
                        <option value={_channel.id} key={_channel.id}>
                          {getDisplayMessage(_channel.label, _channel.customLabel)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <Player
                cmcd={{
                  sessionId: browserSessionIdentifier,
                  contentId: `${params.eventId}/${channel.label}`,
                }}
                channelLabel={channel.label}
                backgroundImage={backgroundImage()}
                hlsPlaybackUrl={channel.hlsPlaybackUrl}
                dashPlaybackUrl={channel.dashPlaybackUrl}
                isReady={isReady}
                isDrm={isDrm()}
                isVod={isVod()}
                lowLatency={lowLatency}
                fakeLiveLayout={false}
                playerProblemHandler={playerProblemHandler}
                ignoreChrome105VjsFix={event.ignoreChrome105VjsFix === true}
                isEmbed={true}
                manualTriggerControl={customConfig.manualTriggerControl}
                userActiveHandler={userActiveHandler}
                userInactiveHandler={userInactiveHandler}
                enableCasting={customConfig.enableCasting === true}
                forcePlayer={customConfig.forcePlayer}
                embedHeaderComponentHtml={embedHeaderComponentHtml}
                audioChangeHandler={audioChangeHandler}
              ></Player>
            </div>
          )}
        </main>
      )}
    </Fragment>
  );
}
