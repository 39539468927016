import React, { useEffect, useCallback, useState, useRef } from 'react';
import VideojsPlayer from 'components/videojsPlayer';
import JwPlayer from 'components/jwPlayer';
import TheoPlayer from './theoPlayer';
import HlsjsPlayer from './hlsjsPlayer';
import * as uaUtils from 'utils/uaUtils';
import logger from 'utils/loggerUtils';
import * as API from '../api/baseAPI';

import {
  accumulatePlaybackLogsViewSecond,
  sendPlaybackLogs,
  accumulatePlaybackStalledSecond,
  appendEventLog,
  updateMetadata,
} from 'redux/playbackLogsSlice';
import { fireGTMHeartbeatIfNeeded, fireGTMVideoProgressIfNeeded, resetGTM } from 'redux/gtmSlice';
import { fireGAUAVideoProgressIfNeeded, resetGAUA } from 'redux/gaUaSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import murmur from 'murmurhash-js';
import { checkPlaybackAvailable } from 'utils/playbackUtils';

import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';

import 'styles/scss/components/player.scss';
import { useSearchParams } from 'react-router-dom';
import useVisibilityChange from 'hook/useVisibilityChange';

let lastSendApiLogTs = Date.now();
export const getAndUpdateLastSendApiLogTs = () => {
  let interval = dayjs(Date.now()).diff(dayjs(lastSendApiLogTs), 'second');
  lastSendApiLogTs = Date.now();
  return interval;
};

const getPlayerAndPlaybackTypePair = (props) => {
  const _lowLatency = props.lowLatency;
  const _withDashPlayback = props.dashPlaybackUrl && props.dashPlaybackUrl !== '';
  const _isDrm = props.isDrm;
  const _ignoreChrome105VjsFix = props.ignoreChrome105VjsFix;
  const _enableCasting = props.enableCasting;
  const _isVod = props.isVod;
  const _fakeLiveLayout = props.fakeLiveLayout;

  if (_lowLatency) {
    if (!uaUtils.isSafari() && !uaUtils.isIOS() && _withDashPlayback) {
      return ['theo', 'dash'];
    }
    return ['hlsjs', 'hls'];
  }
  if (_isDrm) {
    if (!uaUtils.isSafari() && !uaUtils.isIOS() && _withDashPlayback) {
      return ['theo', 'dash'];
    }
    return ['theo', 'hls'];
  }
  if (!uaUtils.isSafari()) {
    if (uaUtils.isChrome105() && !_ignoreChrome105VjsFix) {
      return ['jw', 'hls'];
    }
    if (_enableCasting) {
      return ['jw', 'hls'];
    }
    if (!_isVod || _fakeLiveLayout) {
      return ['vjs', 'hls'];
    }
  }
  return ['jw', 'hls'];
};

export default function Player(props) {
  const {
    backgroundImage,
    hlsPlaybackUrl,
    dashPlaybackUrl,
    isReady,
    isDrm,
    isVod,
    fakeLiveLayout,
    forcePlayer,
    playerProblemHandler,
    isEmbed,
    lowLatency,
    manualTriggerControl,
    ignoreChrome105VjsFix,
    userActiveHandler,
    userInactiveHandler,
    enableCasting,
    cmcd,
    channelLabel,
    children,
    embedHeaderComponentHtml,
    audioChangeHandler,
  } = props;
  const dispatch = useDispatch();
  const documentVisible = useVisibilityChange();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const specificPlayer = useRef(
    forcePlayer && forcePlayer !== ''
      ? forcePlayer
      : searchParams.get('player') && searchParams.get('player') !== ''
      ? searchParams.get('player')
      : null
  );
  const [playerHasError, setPlayerHasError] = useState(false);
  const [playbackAvailable, setPlaybackAvailable] = useState(false);
  const [playerOptions, setPlayerOptions] = useState({
    autoplay: true,
    src: getPlayerAndPlaybackTypePair(props)[1] === 'hls' ? hlsPlaybackUrl : dashPlaybackUrl,
    srcType: getPlayerAndPlaybackTypePair(props)[1],
    isVod: isVod,
    isDrm: isDrm,
    drmConfig: {
      drmTokenHeaderName: '',
      fairPlayCertificateUrl: '',
      fairPlayProxyUrl: '',
      fairPlayToken: '',
      widevineProxyUrl: '',
      widevineToken: '',
      drmToken: '',
    },
    containQA: false,
    isEmbed: isEmbed,
    fakeLiveLayout: fakeLiveLayout,
    checkingInterval: parseInt(process.env.REACT_APP_PLAYER_CHECKING_INTERVAL_SECOND) * 1000,
    manualTriggerControl: manualTriggerControl,
    cmcd: cmcd,
    castReceiverAppId: '',
  });
  const [useHlsjs, setUseHlsjs] = useState(
    specificPlayer.current
      ? specificPlayer.current === 'hlsjs'
      : getPlayerAndPlaybackTypePair(props)[0] === 'hlsjs'
  );
  const [useVideojs, setUseVideojs] = useState(
    specificPlayer.current
      ? specificPlayer.current === 'vjs'
      : getPlayerAndPlaybackTypePair(props)[0] === 'vjs'
  );
  const [useJW, setUseJW] = useState(
    specificPlayer.current
      ? specificPlayer.current === 'jw'
      : getPlayerAndPlaybackTypePair(props)[0] === 'jw'
  );
  const [useTHEO, setUseTHEO] = useState(
    specificPlayer.current
      ? specificPlayer.current === 'theo'
      : getPlayerAndPlaybackTypePair(props)[0] === 'theo'
  );

  useEffect(() => {
    const _props = {
      enableCasting: enableCasting,
      dashPlaybackUrl: dashPlaybackUrl,
      fakeLiveLayout: fakeLiveLayout,
      ignoreChrome105VjsFix: ignoreChrome105VjsFix,
      isDrm: isDrm,
      isVod: isVod,
      lowLatency: lowLatency,
    };
    setPlayerOptions((o) => ({
      ...o,
      src: getPlayerAndPlaybackTypePair(_props)[1] === 'hls' ? hlsPlaybackUrl : dashPlaybackUrl,
      srcType: getPlayerAndPlaybackTypePair(_props)[1],
      isVod: isVod,
      isDrm: isDrm,
      fakeLiveLayout: fakeLiveLayout,
    }));
    setUseHlsjs(
      forcePlayer
        ? forcePlayer === 'hlsjs'
        : specificPlayer.current
        ? specificPlayer.current === 'hlsjs'
        : getPlayerAndPlaybackTypePair(_props)[0] === 'hlsjs'
    );
    setUseVideojs(
      forcePlayer
        ? forcePlayer === 'vjs'
        : specificPlayer.current
        ? specificPlayer.current === 'vjs'
        : getPlayerAndPlaybackTypePair(_props)[0] === 'vjs'
    );
    setUseJW(
      forcePlayer
        ? forcePlayer === 'jw'
        : specificPlayer.current
        ? specificPlayer.current === 'jw'
        : getPlayerAndPlaybackTypePair(_props)[0] === 'jw'
    );
    setUseTHEO(
      forcePlayer
        ? forcePlayer === 'theo'
        : specificPlayer.current
        ? specificPlayer.current === 'theo'
        : getPlayerAndPlaybackTypePair(_props)[0] === 'theo'
    );
  }, [
    enableCasting,
    dashPlaybackUrl,
    forcePlayer,
    hlsPlaybackUrl,
    fakeLiveLayout,
    ignoreChrome105VjsFix,
    isDrm,
    isVod,
    lowLatency,
  ]);

  useEffect(() => {
    setPlayerOptions((o) => ({
      ...o,
      isEmbed: isEmbed,
    }));
  }, [isEmbed]);

  useEffect(() => {
    setPlayerOptions((o) => ({
      ...o,
      manualTriggerControl: manualTriggerControl,
    }));
  }, [manualTriggerControl]);

  useEffect(() => {
    setPlayerOptions((o) => ({
      ...o,
      cmcd: cmcd,
    }));
  }, [cmcd]);

  const playerErrorHandler = useCallback(() => {
    if (isReady) {
      playerProblemHandler();
      setPlayerHasError(true);
      if (userActiveHandler) {
        userActiveHandler();
      }
    }
  }, [isReady, playerProblemHandler, userActiveHandler]);

  const playerStalledHandler = useCallback(() => {
    logger.info('player stalled');
    dispatch(accumulatePlaybackStalledSecond(playerOptions.checkingInterval / 1000));
    playerProblemHandler();
  }, [playerProblemHandler, dispatch, playerOptions.checkingInterval]);

  const playerStateCheckHandler = useCallback(
    (cb) => {
      if (cb.state === 'instancePlaying') {
        dispatch(accumulatePlaybackLogsViewSecond(playerOptions.checkingInterval / 1000));
        dispatch(fireGTMVideoProgressIfNeeded());
        dispatch(fireGTMHeartbeatIfNeeded());
        dispatch(fireGAUAVideoProgressIfNeeded());
      }
    },
    [dispatch, playerOptions.checkingInterval]
  );

  useEffect(() => {
    if (documentVisible) {
      // logger.info('__send gtm ga4 heartbeat timer start');
      const ga4HbTimer = setInterval(() => {
        dispatch(fireGTMHeartbeatIfNeeded());
      }, 1000);
      return () => {
        // logger.info('__send gtm ga4 heartbeat timer stop');
        clearInterval(ga4HbTimer);
      };
    }
  }, [documentVisible, dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      /*
      event.preventDefault();
      dispatch(
        appendEventLog({
          ca: new Date().toISOString(), // createdAt
          en: 'viewend', // eventName
        })
      );
      let interval = getAndUpdateLastSendApiLogTs();
      dispatch(sendPlaybackLogs({ interval: interval }));
      */
    };

    let apiSendIntervalSecond = parseInt(process.env.REACT_APP_PLAYBACK_LOGS_SEND_INTERVAL_SECOND);
    let playbackLogsTimer = null;
    // murmur.murmur3(key, seed) - Runs the murmur3 hash algorithm on the string key with initial seed seed.
    let firstLogIntervalSecond = murmur(uuidv4(), apiSendIntervalSecond) % apiSendIntervalSecond;
    lastSendApiLogTs = Date.now();
    let firstLogTimer = setTimeout(() => {
      dispatch(sendPlaybackLogs({ interval: getAndUpdateLastSendApiLogTs() })); // first call range in 0s ~ REACT_APP_PLAYBACK_LOGS_SEND_INTERVAL_SECOND period
      playbackLogsTimer = setInterval(() => {
        dispatch(
          appendEventLog({
            ca: new Date().toISOString(), // createdAt
            en: 'hb', // eventName
          })
        );
        dispatch(sendPlaybackLogs({ interval: getAndUpdateLastSendApiLogTs() }));
      }, apiSendIntervalSecond * 1000);
    }, firstLogIntervalSecond * 1000);

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      if (firstLogTimer) {
        clearTimeout(firstLogTimer);
      }
      if (playbackLogsTimer) {
        clearInterval(playbackLogsTimer);
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
      dispatch(resetGTM());
      dispatch(resetGAUA());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isReady) {
      dispatch(resetGTM());
      dispatch(resetGAUA());
      setPlayerHasError(false);
      if (userActiveHandler) {
        userActiveHandler();
      }
    }
  }, [dispatch, isReady, userActiveHandler]);

  useEffect(() => {
    if (channelLabel) {
      let interval = dayjs(Date.now()).diff(dayjs(lastSendApiLogTs), 'second');
      dispatch(sendPlaybackLogs({ interval: interval }));
      lastSendApiLogTs = Date.now();
      dispatch(
        appendEventLog({
          ca: new Date().toISOString(), // createdAt
          en: 'channelchange', // eventName
          cl: channelLabel, // channelLabel
        })
      );
      dispatch(updateMetadata({ cl: channelLabel }));
    }
  }, [dispatch, channelLabel]);

  useEffect(() => {
    // look like it's ok to check either `playerOptions.isDrm` or `isDrm `
    if (isDrm !== null) {
      let cp = isDrm === true ? (playerOptions.srcType === 'hls' ? 'fp' : 'wv') : null;
      dispatch(updateMetadata({ cp }));
      logger.log('adding - update drmMetadata isDrm', cp);
    }
  }, [dispatch, isDrm, playerOptions.srcType]);

  useEffect(() => {
    let playbackCheckerTimer = null;
    let ignore = false;
    const playbackChecker = () => {
      checkPlaybackAvailable(hlsPlaybackUrl)
        .then(() => {
          if (!ignore) {
            if (isDrm) {
              API.getDrmToken()
                .then((response) => {
                  if (!ignore) {
                    setPlayerOptions((o) => ({
                      ...o,
                      drmConfig: {
                        drmToken: response.data.token,
                        drmTokenHeaderName: `${process.env.REACT_APP_AXINOM_DRM_HEADER_NAME}`,
                        fairPlayCertificateUrl: `${process.env.REACT_APP_AXINOM_FAIRPLAY_CERTIFICATE_URL}`,
                        fairPlayLicenseAcquisitionUrl: `${process.env.REACT_APP_AXINOM_FAIRPLAY_LICENSE_ACQUISITION_URL}`,
                        playreadyLicenseAcquisitionUrl: `${process.env.REACT_APP_AXINOM_PLAYREADY_LICENSE_ACQUISITION_URL}`,
                        widevineLicenseAcquisitionUrl: `${process.env.REACT_APP_AXINOM_WIDEVINE_LICENSE_ACQUISITION_URL}`,
                      },
                    }));
                    setPlaybackAvailable(true);
                  }
                })
                .catch((err) => {
                  logger.error(err);
                  if (!ignore) {
                    setPlayerHasError(true);
                    setPlaybackAvailable(false);
                  }
                });
            } else {
              setPlaybackAvailable(true);
            }
          }
        })
        .catch(() => {
          if (!ignore) {
            setPlaybackAvailable(false);
            playbackCheckerTimer = setTimeout(() => {
              playbackChecker();
            }, 5000);
          }
        });
    };
    if (isReady && hlsPlaybackUrl && hlsPlaybackUrl !== null) {
      playbackChecker();
    } else {
      setPlaybackAvailable(false);
    }
    return () => {
      ignore = true;
      if (playbackCheckerTimer) {
        clearInterval(playbackCheckerTimer);
        playbackCheckerTimer = null;
      }
    };
  }, [isDrm, isReady, hlsPlaybackUrl]);

  useEffect(() => {
    setPlayerHasError(false);
  }, [hlsPlaybackUrl]);

  return (
    <div className={`player-container${isEmbed ? ' embed' : ''}`}>
      {children && children}
      <div
        className={`player-flip${isReady && playerHasError ? ' error' : ''}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      {isReady && playbackAvailable && !playerHasError && (
        <div className="player-wrapper">
          {useHlsjs && (
            <HlsjsPlayer
              options={playerOptions}
              playerStateCheckHandler={playerStateCheckHandler}
              playerErrorHandler={playerErrorHandler}
              playerStalledHandler={playerStalledHandler}
              userActiveHandler={userActiveHandler}
              userInactiveHandler={userInactiveHandler}
              enableCasting={enableCasting}
            ></HlsjsPlayer>
          )}
          {useJW && (
            <JwPlayer
              options={playerOptions}
              playerStateCheckHandler={playerStateCheckHandler}
              playerErrorHandler={playerErrorHandler}
              playerStalledHandler={playerStalledHandler}
              userActiveHandler={userActiveHandler}
              userInactiveHandler={userInactiveHandler}
              enableCasting={enableCasting}
              embedHeaderComponentHtml={embedHeaderComponentHtml}
              audioChangeHandler={audioChangeHandler}
            ></JwPlayer>
          )}
          {useTHEO && (
            <TheoPlayer
              options={playerOptions}
              playerStateCheckHandler={playerStateCheckHandler}
              playerErrorHandler={playerErrorHandler}
              playerStalledHandler={playerStalledHandler}
              userActiveHandler={userActiveHandler}
              userInactiveHandler={userInactiveHandler}
              enableCasting={enableCasting}
              embedHeaderComponentHtml={embedHeaderComponentHtml}
              audioChangeHandler={audioChangeHandler}
            ></TheoPlayer>
          )}
          {useVideojs && (
            <VideojsPlayer
              options={playerOptions}
              playerStateCheckHandler={playerStateCheckHandler}
              playerErrorHandler={playerErrorHandler}
              playerStalledHandler={playerStalledHandler}
              userActiveHandler={userActiveHandler}
              userInactiveHandler={userInactiveHandler}
              enableCasting={enableCasting}
              embedHeaderComponentHtml={embedHeaderComponentHtml}
              audioChangeHandler={audioChangeHandler}
            ></VideojsPlayer>
          )}
        </div>
      )}
      {isReady && playerHasError && (
        <div className="player-error">
          <ErrorIcon sx={{ color: '#ffffff', fontSize: 50 }}></ErrorIcon>
          <div className="error">
            <div className="title">{t('event.playback.error-title')}</div>
            <div className="text">{t('event.playback.error-message')}</div>
          </div>
        </div>
      )}
    </div>
  );
}
