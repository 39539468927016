import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { setLowlatencyMode } from 'redux/hlsjsCtrSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, FormGroup } from '@mui/material';
import 'styles/scss/components/hlsjsPlayerCtr.scss';

const switchStyle = {
  borderRadius: 2,
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#0C6251',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#3FBB9B',
  },
  '&:hover .MuiSwitch-switchBase': {
    color: 'green',
  },
};

export default function HlsjsPlayerCtr(props) {
  const { debugMode, lowLatency } = props;
  const { lowlatencyMode } = useSelector((state) => state.hlsjsCtr);
  const dispatch = useDispatch();
  const { latencyStat } = useSelector((state) => state.hlsjsStat);
  const [latencyLabel, setLatencyLabel] = useState('');

  useEffect(() => {
    if (!latencyStat || !latencyStat.latency) {
      return;
    }
    setLatencyLabel(`(${parseFloat(latencyStat.latency / 1000).toFixed(1)} s)`);
  }, [lowlatencyMode, latencyStat]);

  const handleChange = (event) => {
    dispatch(setLowlatencyMode(event.target.checked));
  };

  return (
    <FormGroup sx={{ display: 'inline' }}>
      {lowLatency && (
        <FormControlLabel
          control={<Switch checked={lowlatencyMode} onChange={handleChange} sx={switchStyle} />}
          label="Low latency"
        />
      )}
      {debugMode && (
        <div className="latency-label">
          <p>{latencyLabel}</p>
        </div>
      )}
    </FormGroup>
  );
}
